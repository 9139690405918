import type { SVGProps } from 'react';
const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.2919 11.9284C12.8095 11.9284 14.0408 10.729 14.0408 9.24994C14.0408 7.77087 12.8095 6.57153 11.2919 6.57153C9.77315 6.57153 8.54297 7.77087 8.54297 9.24994C8.54297 10.729 9.77315 11.9284 11.2919 11.9284Z"
      fill="#A1A1AA"
    />
    <path
      d="M21.4405 9.03851C21.4405 8.96803 17.9867 3.25879 11.2907 3.25879C4.59471 3.25879 1.14097 9.03851 1.14097 9.03851L1 9.24997L1.07048 9.46142C1.07048 9.5319 4.52422 15.2411 11.2202 15.2411C17.9163 15.2411 21.37 9.46142 21.37 9.46142L21.4405 9.24997V9.03851ZM11.2907 12.9856C9.1762 12.9856 7.48457 11.294 7.48457 9.24997C7.48457 7.20592 9.1762 5.51429 11.2907 5.51429C13.4053 5.51429 15.0969 7.20592 15.0969 9.24997C15.0969 11.294 13.4053 12.9856 11.2907 12.9856Z"
      fill="#A1A1AA"
    />
    <rect
      x="1.51055"
      y="16.789"
      width="23.8761"
      height="2"
      transform="rotate(-42.1688 1.51055 16.789)"
      fill="#A1A1AA"
      stroke="white"
    />
  </svg>
);
export default SvgEyeOff;
