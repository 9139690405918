class CustomError extends Error {
    public statusCode?: number;
    public response: any;
    public screeName?: string;
    public api?: string;
    public isToast: boolean = false;
  
    constructor(message: string, statusCode?: number, response?: any, screeName?: string, api?: string, isToast: boolean = false) {
      super(message);
      this.statusCode = statusCode;
      this.response = response;
      this.screeName = screeName;
      this.api = api;
      this.isToast = isToast;
      this.name = this.constructor.name;
      Error.captureStackTrace(this, this.constructor);
    }
  }

  export default CustomError
  