interface ValidationResult {
  error: string;
  isValid: boolean;
}

const isValidFields = (
  value: string,
  name: string,
  maxLength: number
): ValidationResult => {
  if (value.trim() === '') {
    return { error: `${name} is required`, isValid: false };
  }
  if (maxLength > 0 && value.length > maxLength) {
    return {
      error: `${name} cannot exceed ${maxLength} characters`,
      isValid: false,
    };
  }
  return { error: '', isValid: true };
};

export { isValidFields };
