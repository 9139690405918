import React, { SVGProps } from 'react';

const RolesAndPermissionIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="15"
        height="18"
        viewBox="0 0 15 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.30762 8.56055C8.48435 8.56055 9.50233 8.13788 10.3338 7.30644C11.1652 6.47499 11.5879 5.45502 11.5879 4.28028C11.5879 3.10355 11.1652 2.08557 10.3338 1.25412C9.50233 0.42267 8.48237 0 7.30762 0C6.13089 0 5.11291 0.42267 4.28146 1.25412C3.45001 2.08557 3.02734 3.10553 3.02734 4.28028C3.02734 5.45701 3.45001 6.47499 4.28146 7.30644C5.1149 8.13788 6.13288 8.56055 7.30762 8.56055Z"
          fill="currentColor"
        />
        <path
          d="M14.7994 13.6663C14.7756 13.3191 14.726 12.942 14.6545 12.5432C14.5831 12.1404 14.4898 11.7614 14.3787 11.4141C14.2636 11.0549 14.1069 10.6997 13.9144 10.3604C13.714 10.0072 13.4778 9.70158 13.2139 9.44758C12.9381 9.18366 12.5987 8.97133 12.2078 8.81655C11.8189 8.66177 11.3863 8.58438 10.9239 8.58438C10.7434 8.58438 10.5667 8.65979 10.2274 8.88005C10.0191 9.01697 9.77499 9.17374 9.50313 9.34638C9.27096 9.49521 8.95544 9.63411 8.56452 9.75913C8.18352 9.88216 7.79856 9.94367 7.41756 9.94367C7.03656 9.94367 6.65159 9.88216 6.2706 9.75913C5.88166 9.63411 5.56615 9.49521 5.33398 9.34638C5.0641 9.17374 4.82002 9.01697 4.6077 8.87807C4.26837 8.6578 4.09375 8.5824 3.91119 8.5824C3.44883 8.5824 3.01822 8.65979 2.6273 8.81457C2.23638 8.96935 1.89904 9.18168 1.62123 9.4456C1.35731 9.69761 1.12117 10.0052 0.920746 10.3584C0.728262 10.6977 0.571497 11.0529 0.456404 11.4121C0.34528 11.7594 0.252014 12.1384 0.180577 12.5412C0.10914 12.9401 0.0615152 13.3171 0.0357185 13.6644C0.0119061 14.0037 0 14.3569 0 14.7141C0 15.6428 0.295671 16.3929 0.87709 16.9465C1.45057 17.4922 2.21257 17.77 3.1353 17.77H11.6939C12.6186 17.77 13.3786 17.4922 13.9521 16.9465C14.5335 16.3929 14.8292 15.6408 14.8292 14.7141C14.8371 14.3589 14.8232 14.0057 14.7994 13.6663Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default RolesAndPermissionIcon;
