import './uploadButton.css';

interface IUploadButtonProps {
  children: React.ReactNode;
  onUpload?: React.ChangeEventHandler<HTMLInputElement>;
}

const UploadButtton = (props: IUploadButtonProps) => {
  return (
    <>
      <div className="upload-btn-wrapper">
        <button className="border border-neutral-200  rounded h-10 py-2 px-3 bg-neutral-0 font-medium text-base cursor-pointer">
          {props.children}
        </button>
        <input type="file" name="myfile" onChange={props.onUpload} />
      </div>
    </>
  );
};

export default UploadButtton;
