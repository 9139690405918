import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setShowlogoutModal } from './logout.slice';
import Content from '../../../constants/ContentFile.json';
import { AlertModal, deleteCookie } from '@kamet/common';
import { logout } from '../../../common/securityLayer/KeyCloakAuthentication/useAuth';

const LogoutPage = () => {
  const logoutContent = Content.LogoutDetails;
  const dispatch = useDispatch();
  const modalState = useSelector((state: RootState) => state.Logout);

  const onClickCloseLogoutModal = () => {
    dispatch(setShowlogoutModal(false));
  };
  const onClickLogout = () => {
    dispatch(setShowlogoutModal(false));
    logout();
  };
  return (
    <div>
      <AlertModal
        title={logoutContent.title}
        children={logoutContent.description}
        closeButtonText={logoutContent.cancelButtonText}
        submitButtonText={logoutContent.logoutButtonText}
        onClose={onClickCloseLogoutModal}
        onSubmit={onClickLogout}
        isOpen={modalState.showLogoutModal}
        submitButtonVariant={true}
      />
    </div>
  );
};

export default LogoutPage;
