import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../redux/store';
import CustomError from '../../../common/service/CustomError';
import {
  editOrganizationData,
  getOrganizationData,
} from '../Organization.service';
import { adaptOrganizationDataResponse } from './Settings.adapter';
import { fetchUserInfo } from '../../SplashScreen/SplashSlice';

export interface ISettingsState {
  teamName: string;
  teamNameErrorMsg: string;
  costPerMinute: string;
  costErrorMessage: string;
  profileUrl: string;
  profileImageErrorMsg: string;
  teamNameFirstletter: string;
  id: number | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: CustomError | null;
}

const initialState: ISettingsState = {
  teamName: '',
  teamNameErrorMsg: '',
  costPerMinute: '',
  costErrorMessage: '',
  profileUrl: '',
  profileImageErrorMsg: '',
  teamNameFirstletter: '',
  id: null,
  loading: 'idle',
  error: null,
};

const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTeamName: (state, action) => {
      state.teamName = action.payload;
    },
    setTeamNameErrorMsg: (state, action) => {
      state.teamNameErrorMsg = action.payload;
    },
    setCostPerminute: (state, action) => {
      state.costPerMinute = action.payload;
    },
    setCostErrorMessage: (state, action) => {
      state.costErrorMessage = action.payload;
    },
    setProfileImage: (state, action) => {
      state.profileUrl = action.payload;
    },
    setprofileImageErrorMsg: (state, action) => {
      state.profileImageErrorMsg = action.payload;
    },

    setTeamNameFirstLetter: (state, action) => {
      state.teamNameFirstletter = action.payload;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    reset: (state) => {
      state.teamNameErrorMsg = '';
      state.profileImageErrorMsg = '';
      state.costErrorMessage = '';
    },
    getOrganizationDataStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getOrganizationDataSuccess(state) {
      state.loading = 'succeeded';
      state.error = null;
    },
    getOrganizationDataFailure(state, action) {
      state.loading = 'failed';
      state.error = action.payload;
    },
  },
});
export const {
  setTeamName,
  setTeamNameErrorMsg,
  setCostPerminute,
  setCostErrorMessage,
  setProfileImage,
  setprofileImageErrorMsg,
  setTeamNameFirstLetter,
  reset,
  setId,
  getOrganizationDataStart,
  getOrganizationDataFailure,
  getOrganizationDataSuccess,
} = SettingsSlice.actions;

// fetch organization Data
export const fetchOrganizationData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getOrganizationDataStart());
    const response = await getOrganizationData();
    const organizationData = adaptOrganizationDataResponse(response);
    dispatch(getOrganizationDataSuccess());
    dispatch(setTeamName(organizationData.teamName));
    dispatch(setProfileImage(organizationData.profileUrl));
    dispatch(setId(organizationData.id));
    dispatch(setCostPerminute(organizationData.cost));
    dispatch(reset());
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getOrganizationDataFailure(error));
    } else {
      dispatch(getOrganizationDataFailure(new CustomError('Unknown error')));
    }
  }
};

// set organization Data
export const setOrganizationData =
  (id: number | null, formData: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getOrganizationDataStart());
      const response = await editOrganizationData(id, formData);
      dispatch(fetchUserInfo());
      dispatch(getOrganizationDataSuccess());
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getOrganizationDataFailure(error));
      } else {
        dispatch(getOrganizationDataFailure(new CustomError('Unknown error')));
      }
    }
  };
export default SettingsSlice.reducer;
export const selectLoading = (state: RootState) =>
  state.organizationSettings.loading;
export const selectError = (state: RootState) =>
  state.organizationSettings.error;
