import type { SVGProps } from 'react';
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.83333 2C4.16396 2 2 4.16396 2 6.83333C2 9.50271 4.16396 11.6667 6.83333 11.6667C9.50271 11.6667 11.6667 9.50271 11.6667 6.83333C11.6667 4.16396 9.50271 2 6.83333 2ZM0 6.83333C0 3.05939 3.05939 0 6.83333 0C10.6073 0 13.6667 3.05939 13.6667 6.83333C13.6667 8.3596 13.1663 9.76899 12.3206 10.9064L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10.9064 12.3206C9.76899 13.1663 8.3596 13.6667 6.83333 13.6667C3.05939 13.6667 0 10.6073 0 6.83333Z"
      fill="#A1A1AA"
    />
  </svg>
);
export default SvgSearch;
