import { ThemeCard } from '@kamet/common';
import Content from '../../../constants/ContentFile.json';

const pageContent = Content.providerIntegration.noProvider;

const NoIntegratorsCard = () => {
  return (
    <>
      <ThemeCard>
        <div className="grid md:grid-cols-12">
          <div className="md:col-span-10 lg:col-span-8">
            <h1 className="text-lg text-neutral-950 font-semibold">
              {pageContent.hello}
            </h1>
            <div className="text-base text-neutral-500 font-medium py-4 ">
              <p className="mb-2">{pageContent.title} </p>
              <p className="mb-2">{pageContent.descriptionFirstLine}</p>
              <p className="mb-2">{pageContent.descriptionSecondLine}</p>
              <p>{pageContent.stayTuned}</p>
            </div>
          </div>
        </div>
      </ThemeCard>
    </>
  );
};

export default NoIntegratorsCard;
