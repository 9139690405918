import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchUserInfo,
  selectUserInfo,
  selectUserInfoError,
  selectUserInfoLoading,
} from './SplashSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import routeConstants from '../../constants/routeConstants.json';
import { Loader } from '@kamet/common';
import ErrorPage from '../../common/component/ErrorPage/Errorpage';
import Content from '../../constants/ContentFile.json';
import Permission from '../../common/securityLayer/Permission/Permission';
const SplashScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;
  const userDetails = useSelector((state: RootState) => selectUserInfo(state));
  const { createPermissionRoutes } = Permission();
  const pathHashValue = localStorage.getItem('path');
  const currentRoute = pathHashValue && pathHashValue.split('#').pop();


  useEffect(() => {
    dispatch(fetchUserInfo());
  }, []);

  useEffect(() => {
    if (userDetails.isLogin) {
      createPermissionRoutes(Content.menuList, Content.roleBasedMenuList);
      navigate(currentRoute ? currentRoute : routeConstants.DASHBOARD);
    }
  }, [userDetails.isLogin]);

  const Loading = useSelector((state: RootState) =>
    selectUserInfoLoading(state)
  );
  const error = useSelector((state: RootState) => selectUserInfoError(state));

  const navigateLogin = () => {
    dispatch(fetchUserInfo());
  }

  
  if (Loading === 'pending') return <Loader />;
  if (error) {
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Retry"
        isButtonVisible
        action={navigateLogin}
      />
    );
  }

  return (
    <div>
      <Loader />
    </div>
  );
};

export default SplashScreen;
