import { Button, ThemeCard } from '@kamet/common';
import pagecontents from '../../../constants/ContentFile.json';
//import { useNavigate } from 'react-router-dom';
interface IErrorPage {
  statusCode: string;
  description?: string;
  buttonText: string;
  isButtonVisible?: boolean;
  action?: () => void;
  customStyle?: string;
}
const ErrorPage = (props: IErrorPage) => {
  const contents = pagecontents.errorPage;
  // Define button text mapping based on status code
  const errorButtonText: { [key: string]: string } = {
    "404": 'Go to Home Page',
    "500": 'Try Again Later',
    "401": 'Reload',
    "400": 'Reload',
  };
  const errorDescription: { [key: string]: string } = {
    "400": 'Request failed with status code 400',
    "404": 'Request failed with status code 404',
    "500": 'Request failed with status code 500',
    "401": 'Request failed with status code 401',
  };
  // Determine the button text based on the status code
  const buttonText = props.statusCode
    ? errorButtonText[props.statusCode]
    : 'Reload';
  const descriptionText = props.statusCode
    ? props.description ? props.description : errorDescription[props.statusCode]
    : 'Request failed';

  return (
    <div className={`bg-neutral-50  items-start h-screen ${props.customStyle}`}>
      <h1 className="text-2xl text-neutral-950 font-semibold py-4">
        Error {props.statusCode}
      </h1>
      <ThemeCard>
        <div className="grid md:grid-cols-12   ">
          <div className="md:col-span-10 lg:col-span-7 ">
            <h1 className="text-lg text-neutral-950 font-semibold ">
              {contents.issueText}
            </h1>
            <h1 className="text-base text-neutral-500 font-medium py-4 ">
              {descriptionText}
            </h1>
            {props.isButtonVisible && (
              <Button onClick={props.action}>{buttonText}</Button>
            )}
          </div>
        </div>
      </ThemeCard>
    </div>
  );
};
export default ErrorPage;
