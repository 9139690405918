import React from 'react';

// Avatar component - displays images, such as profile pictures, in an avatar view.

interface IAvatar {
  children: React.ReactNode;
}

const Avatar = (props: IAvatar) => {
  return (
    <div className="bg-primary-600 h-10 w-10 rounded font-semibold text-lg text-white flex items-center justify-center overflow-hidden">
      {props.children}
    </div>
  );
};

export default Avatar;
