import { Navigate, Outlet, useLocation } from 'react-router-dom';
import RouteConstants from '../../../constants/routeConstants.json';
import { getCookie } from '@kamet/common';
interface IpermissionlistProps {
  permissionMenuList: any;
}

const ProtectedRoute = (props: IpermissionlistProps) => {
  const location = useLocation();
  const path = location.pathname;
  const isAuthenticatedUser = getCookie('access_token');
  let loadComponent = false;
  let route = RouteConstants.SPLASHSCREEN;

  // checking and redirecting according to permissions
  if (isAuthenticatedUser) {
    props.permissionMenuList.filter((routeItem: any) => {
      if (routeItem.path === path) {
        loadComponent = true;
      } else {
        route = RouteConstants.NO_ACCESS;
      }
    });
  }

  return loadComponent ? <Outlet /> : <Navigate to={route} replace />;
};

export default ProtectedRoute;
