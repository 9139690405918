import { del, get, patch, post, put } from '../../common/service/apiClient';
import axios from 'axios';
import {
  GET_ORGANIZATION_DATA,
  GET_ROLES_LIST,
  GET_USERS,
  SET_ORGANIZATION_DATA,
  UPDATE_ROLE,
} from '../../common/service/serviceEndPoints';
import CustomError from '../../common/service/CustomError';
import Content from '../../constants/ContentFile.json';
import constants from '../../constants/ContentFile.json';
const staticContent = constants.organization;

const errorMessage = Content.organization.errorMessage;

// function to get users list
export const getUsers = async (searchKey?: string): Promise<any> => {
  try {
    const url = searchKey ? `${GET_USERS}?search=${searchKey}` : GET_USERS;
    const response = await get<any>(url);
    return response&&response.data&&response.data.results;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(errorMessage.fetchUsersError, 500, '');
    }
  }
};

// function to get role list
export const getRoles = async (): Promise<any> => {
  try {
    const response = await get<any>(GET_ROLES_LIST);
    return response && response.data && response.data.results;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(errorMessage.fetchRolesError, 500, '');
    }
  }
};

// role update
export const updateRole = async (
  roleId: number,
  userId: number
): Promise<any> => {
  const data = {
    role: roleId,
  };
  try {
    const response = await patch(`${GET_USERS}${userId}/`, data);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(errorMessage.roleUpdateError, 500, '');
    }
  }
};

// Add new user
export const addNewUser = async (
  email: string,
  role: number | null
): Promise<any> => {
  const userData = {
    email: email,
    role: role,
  };
  try {
    const response = await post(GET_USERS, userData);
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(errorMessage.addNewUserError, 500, '');
    }
  }
};

export const deleteUser = async (userId: number | null): Promise<any> => {
  try {
    const response = await del(`${GET_USERS}${userId}/`);
  } catch (error) {
    // Handle error and throw a custom er.ror or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(errorMessage.deleteuserError, 500, '');
    }
  }
};

// function to get organization data
export const getOrganizationData = async (): Promise<any> => {
  try {
    const response = await get<any>(GET_ORGANIZATION_DATA);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        `${staticContent.settings.organizationGetError}`,
        500,
        ''
      );
    }
  }
};
export const editOrganizationData = async (
  id: number | null,
  data: any
): Promise<any> => {
  try {
    const response = await put(`${SET_ORGANIZATION_DATA}/${id}/`, data, {
      headers: {
        'content-type': 'multipart/form-data',
        Accept: 'application/json',
      },
    });
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        `${staticContent.settings.organizationSettingsError}`,
        500,
        ''
      );
    }
  }
};
