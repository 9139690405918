import { Accordion, AlertModal } from '@kamet/common';
import Content from '../../../constants/ContentFile.json';
import { useEffect, useState } from 'react';
import { getRiskDetailsData } from '../dashboard.service';

interface IRiskDetailsModal {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}
interface RiskDetails {
  Payload: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
  Speed: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
  Location: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
  SafetySensor: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
  CameraSensor: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
  Software: {
    highRisk: string;
    mediumRisk: string;
    lowRisk: string;
  };
}
const RiskContents = Content.dashboardPage.downTimeCardDetails.riskModalData;
const riskDetails: RiskDetails = {
  Payload: {
    highRisk: 'operating with excessive payload.',
    mediumRisk: 'operating with elevated payload',
    lowRisk: 'operating with minor deviations in payload',
  },
  Speed: {
    highRisk: 'operating with excessive speed',
    mediumRisk: 'operating with elevated speed.',
    lowRisk: 'operating with minor speed variations',
  },
  Location: {
    highRisk: 'frequently exceeding the proximity range.',
    mediumRisk: 'occasionally exceeding the proximity range.',
    lowRisk: ' showing minimal deviations from the proximity range.',
  },
  SafetySensor: {
    highRisk: 'experiencing critical issues with the safety sensor.',
    mediumRisk: '',
    lowRisk: '',
  },
  CameraSensor: {
    highRisk: 'experiencing severe malfunctions with camera sensor.',
    mediumRisk: '',
    lowRisk: '',
  },
  Software: {
    highRisk: 'running outdated software versions.',
    mediumRisk: 'running slightly outdated software versions.',
    lowRisk: 'running slightly behind on software updates.',
  },
};

const RiskDetailsModal = (props: IRiskDetailsModal) => {
  useEffect(() => {
    fetchRiskDetails();
  }, [props.data]);
  // api call for risk details
  const fetchRiskDetails = async () => {
    try {
      const response = await getRiskDetailsData(props.data);
      setSample(response.results);
    } catch (error) {}
  };
  const [sample, setSample] = useState<any>([]);

  const TrimWhitespace = (str: string) => {
    return str.replace(/\s+/g, '');
  };

  const renderAccordions = (
    riskData: any,
    risk: 'highRisk' | 'mediumRisk' | 'lowRisk'
  ) => {
    return Object.entries(riskData).map(([zone, zoneDetails]: any) => (
      <div key={zone} className="border-t last:border-b">
        {Object.entries(zoneDetails).map(([key, details]: any) => {
          function getRiskMessage(
            key: keyof RiskDetails,
            riskLevel: 'highRisk' | 'mediumRisk' | 'lowRisk'
          ): string {
            const trimmedKey = TrimWhitespace(key) as keyof RiskDetails;
            const details = riskDetails[trimmedKey];
            return details
              ? details[riskLevel] || 'No details available'
              : 'No details available';
          }
          const message = getRiskMessage(key, risk);
          return (
            <Accordion
              key={key}
              title={`${zone}`}
              children={` ${details.total_machines} ${
                details.total_machines > 1 ? `machines are ` : `machine is `
              } ${message}`}
              details={
                <>
                  {details.machines
                    .map((machine: any) => machine.name)
                    .join(', ')}
                </>
              }
            />
          );
        })}
      </div>
    ));
  };
  return (
    <div>
      <AlertModal
        isOpen={props.isOpen}
        title={''}
        onClose={props.onClose}
        closeButtonText="Close"
        cancelButtonStyle="hidden"
      >
        {sample?.high_risk && Object.keys(sample?.high_risk).length > 0 && (
          <div>
            <h2 className="font-semibold text-neutral-950 text-lg pb-4">
              {RiskContents.highRisk}
            </h2>
            <div
              className={`${
                Object.keys(sample?.high_risk)?.length > 3
                  ? 'overflow-y-scroll  scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-50 '
                  : ''
              } max-h-[250px]`}
            >
              {renderAccordions(sample?.high_risk, 'highRisk')}
            </div>
          </div>
        )}

        {sample?.medium_risk && Object.keys(sample?.medium_risk).length > 0 && (
          <div>
            <h2 className="font-semibold  text-neutral-950 text-lg pb-4">
              {RiskContents.mediumRisk}
            </h2>
            <div
              className={`${
                Object.keys(sample?.medium_risk)?.length > 3
                  ? 'overflow-y-scroll  scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-50 '
                  : ''
              } max-h-[250px]`}
            >
              {renderAccordions(sample?.medium_risk, 'mediumRisk')}
            </div>
          </div>
        )}

        {sample?.low_risk && Object.keys(sample?.low_risk).length > 0 && (
          <div>
            <h2 className="font-semibold  text-neutral-950 text-lg pb-4">
              {RiskContents.lowRisk}
            </h2>
            <div
              className={`${
                Object.keys(sample?.low_risk)?.length > 3
                  ? 'overflow-y-scroll  scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-50 '
                  : ''
              } max-h-[250px]`}
            >
              {renderAccordions(sample?.low_risk, 'lowRisk')}
            </div>
          </div>
        )}
      </AlertModal>
    </div>
  );
};

export default RiskDetailsModal;
