import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import providersReducer from '../features/Integrations/providerSlice';
import DashboardReducer from '../features/Dashboard/DashboardSlice';
import OrganizationSettingsReducer from '../features/Organization/settings/Settings.slice';
import newUserReducer from '../features/Organization/newUser/newUser.slice';
import OrganizationUserReducer from '../features/Organization/users/User.slice';
import userDetailsReducer from '../features/SplashScreen/SplashSlice';
import personalInfoReducer from '../features/PersonalInfo/PersonalInfo.slice';
import PermissionReducer from '../common/securityLayer/Permission/Permission.slice';
import LogoutReducer from '../features/Auth/logout/logout.slice';
import CustomerSettingsReducer from "../features/Settings/CustomerSettings.slice"
// combine all reducers
const rootReducer = combineReducers({
  providers: providersReducer,
  dashboard: DashboardReducer,
  organizationSettings: OrganizationSettingsReducer,
  organizationUsers: OrganizationUserReducer,
  newUser: newUserReducer,
  personalInfo: personalInfoReducer,
  Permissions: PermissionReducer,
  userDetails: userDetailsReducer,
  Logout: LogoutReducer,
  customerInfo:CustomerSettingsReducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;
