import type { SVGProps } from 'react';

const SvgLoaderIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    className="animate-spin"
    style={{
      animation: "spin 500ms linear infinite",
    }}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0ZM20 7C12.8203 7 7 12.8203 7 20C7 27.1797 12.8203 33 20 33C27.1797 33 33 27.1797 33 20C33 12.8203 27.1797 7 20 7Z"
      fill="url(#paint0_linear_1025_1213)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1025_1213"
        x1="0"
        y1="0"
        x2="40"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#8B5CF6" stopOpacity="1" />
        <stop offset="50%" stopColor="#8B5CF6" stopOpacity="0.01" />
        <stop offset="100%" stopColor="#8B5CF6" stopOpacity="0.01" />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgLoaderIcon;
