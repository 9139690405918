import type { SVGProps } from 'react';
const SvgSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM16.2071 10.3739C16.5976 9.98333 16.5976 9.35017 16.2071 8.95964C15.8166 8.56912 15.1834 8.56912 14.7929 8.95964L10.8333 12.9192L9.20711 11.293C8.81658 10.9025 8.18342 10.9025 7.79289 11.293C7.40237 11.6835 7.40237 12.3167 7.79289 12.7072L10.1262 15.0405C10.5168 15.431 11.1499 15.431 11.5404 15.0405L16.2071 10.3739Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSuccess;
