import {
  AlertModal,
  Button,
  DropDown,
  SearchInput,
  Toast,
} from '@kamet/common';
import { Delete } from '@kamet/common/src/assets/icons';
import { MouseEventHandler } from 'react';
import Content from '../../../constants/ContentFile.json';
import NewUser from '../newUser/newUser.page';
import { IUser } from './User.slice';
import withPermissionFlag from '../../../common/hoc/permissionFlag/withPermissionFlag';
import {
  ADD_USER,
  CHANGE_ROLE,
  DELETE_USER,
} from '../../../common/hoc/permissionFlag/permissionFlag';
import clsx from 'clsx';

interface IuserProps {
  roleOptions: { value: number | null; label: string }[];
  data: IUser[];
  activeUser: IUser[];
  pendingUser: IUser[];
  onSearch: React.ChangeEventHandler<HTMLInputElement>;
  searchValue: string;
  clearSearchKey: MouseEventHandler<HTMLDivElement>;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onClickDelete: (userId: number) => void;
  // delete user modal
  deleteUserModal: boolean;
  onCloseDeleteModal: () => void;
  onclickRemove: () => void;
  // Toast
  showToast: boolean;
  toastMessage: string;
  cancelToast: () => void;
  currentUser: any;

  // dropdown
  onChangeRole: (
    e: React.ChangeEvent<HTMLSelectElement>,
    userId: number
  ) => void;

  // error toast
  showErrorToast: boolean;
  errorToastMessage: string;
  closeErrorToast: () => void;
}
const staticContent = Content.organization.users; // static content

const PermissionFlaggedButton = withPermissionFlag(Button);
const PermissionFlaggedDropdown = withPermissionFlag(DropDown);

const UserDetails = (props: IuserProps) => {
  const hasDelete =
    props.currentUser &&
    props.currentUser.rolesNPermissions &&
    props.currentUser.rolesNPermissions.some(
      (permission: any) => permission.codeName === DELETE_USER
    );
  const hasEdit =
    props.currentUser &&
    props.currentUser.rolesNPermissions &&
    props.currentUser.rolesNPermissions.some(
      (permission: any) => permission.codeName === CHANGE_ROLE
    );
  const superAdminRole = [
    {
      label: 'Super Admin',
      value: 0,
    },
  ];
  return (
    <>
      <div className="flex  justify-between gap-3 md:gap-5">
        <div className="w-full">
          <SearchInput
            variant="primary"
            placeholder={staticContent.searchPlaceholder}
            onchange={props.onSearch}
            value={props.searchValue}
            onCancelSearch={props.clearSearchKey}
          />
        </div>
        {props.searchValue && props.searchValue ? (
          <Button
            variant={'primary'}
            customWidth="w-44 sm:w-40"
            onClick={props.onClick}
          >
            {staticContent.searchButtonText}
          </Button>
        ) : (
          <PermissionFlaggedButton
            variant={'primary'}
            customWidth="w-44 sm:w-40"
            onClick={props.onClick}
            action="greyout"
            permissionName={ADD_USER}
          >
            {staticContent.buttonText}
          </PermissionFlaggedButton>
        )}
      </div>
      {/* users list */}
      <section>
        <h4 className="mt-6 font-medium text-lg text-neutral-950">
          {staticContent.activeUsersTitle}
        </h4>

        <div className="border border-neutral-200 rounded bg-neutral-0 mt-2">
          {props.activeUser?.map((list: any) => {
            return (
              <>
                <div className="grid grid-cols-12 px-5 py-2 border-b border-neutral-200 items-center last:border-0">
                  <div className="col-span-5 md:col-span-4  text-base text-neutral-950 font-medium truncate">
                    {list.name}
                  </div>
                  <div className="hidden md:block md:col-span-5 text-base text-neutral-500 font-medium truncate">
                    {list.email}
                  </div>
                  <div className="col-span-7 md:col-span-3  flex justify-end items-center gap-3">
                    <div className="w-36">
                      <PermissionFlaggedDropdown
                        variant="primary"
                        value={list.isSuperUser ? 'Admin' : list.role} // If superadmin, role 'Admin' is assigned
                        options={
                          list.isSuperUser ? superAdminRole : props.roleOptions
                        }
                        placeholder={staticContent.selectPlaceholder}
                        onChange={(e) => {
                          if (
                            !list.isSuperUser &&
                            props.currentUser.userDetails.email !== list.email
                          ) {
                            props.onChangeRole(e, list.id);
                          }
                        }}
                        disabled={
                          props.currentUser.userDetails.email === list.email ||
                          list.isSuperUser
                        }
                        permissionName={CHANGE_ROLE}
                        action="greyout"
                      />
                    </div>

                    <PermissionFlaggedButton
                      variant={
                        list.isSuperUser
                          ? 'disable'
                          : props.currentUser.userDetails.email === list.email
                            ? 'disable'
                            : 'default'
                      }
                      onClick={() => {
                        if (
                          !list.isSuperUser ||
                          list.email !== props.currentUser.userDetails.email
                        ) {
                          props.onClickDelete(list.id);
                        }
                      }}
                      permissionName={DELETE_USER}
                      action="greyout"
                    >
                      <Delete
                        className={clsx('text-error-700', {
                          'text-neutral-300 pointer-events-none':
                            props.currentUser.userDetails.email ===
                              list.email || list.isSuperUser,
                        })}
                      />
                      {/* <Delete
                       className={clsx({
                        'text-neutral-300': (props.currentUser.userDetails.isSuperUser || list.email === props.currentUser.userDetails.email),
                        'text-error-700': ((list.email !== props.currentUser.userDetails.email)&&(!list.isSuperUser)),
                      })}
                      /> */}
                    </PermissionFlaggedButton>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
      {/* pending users list */}
      <section>
        <h4 className="mt-6 text-lg font-medium text-neutral-950">
          {staticContent.pendingInvitees}
        </h4>
        <div className="border border-neutral-200 rounded bg-neutral-0 mt-2">
          {props.pendingUser?.map((list: any) => {
            return (
              <>
                <div className="grid grid-cols-12 px-5 py-2 border-b border-neutral-200 items-center last:border-0">
                  <div className="col-span-5 md:col-span-4  text-base text-neutral-950 font-medium truncate">
                    {list.email}
                  </div>
                  <div className="hidden md:block md:col-span-5 text-base text-neutral-500 font-medium truncate">
                    {list.status}
                  </div>
                  <div className="col-span-7 md:col-span-3  flex justify-end items-center gap-3">
                    <div className="w-32">
                      <PermissionFlaggedDropdown
                        variant="primary"
                        options={props.roleOptions}
                        placeholder={staticContent.selectPlaceholder}
                        value={list.role}
                        onChange={(e) => {
                          props.onChangeRole(e, list.id);
                        }}
                        permissionName={CHANGE_ROLE}
                        action="greyout"
                      />
                    </div>

                    <PermissionFlaggedButton
                      variant="default"
                      onClick={() => props.onClickDelete(list.id)}
                      permissionName={DELETE_USER}
                      action="greyout"
                    >
                      <Delete
                        className={clsx({
                          'text-neutral-300': !(
                            hasDelete ||
                            props.currentUser.userDetails.isSuperUser
                          ),
                          'text-error-700':
                            hasDelete ||
                            props.currentUser.userDetails.isSuperUser,
                        })}
                      />
                    </PermissionFlaggedButton>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </section>
      <NewUser />
      {/* Modal to delete user */}
      <AlertModal
        isOpen={props.deleteUserModal}
        title={staticContent.modalTitle}
        closeButtonText={staticContent.cancelButtonText}
        submitButtonText={staticContent.removeButtonText}
        onClose={props.onCloseDeleteModal}
        onSubmit={props.onclickRemove}
        submitButtonVariant={true}
      >
        {staticContent.modalDescription}
      </AlertModal>

      {
        <Toast
          showToast={props.showToast}
          Message={props.toastMessage}
          cancelToast={props.cancelToast}
          type="success"
        />
      }

      {
        <Toast
          showToast={props.showErrorToast}
          Message={props.errorToastMessage}
          cancelToast={props.closeErrorToast}
          type="error"
        />
      }
    </>
  );
};
export default UserDetails;
