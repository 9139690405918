import axios from 'axios';
import { patch } from '../../common/service/apiClient';
import CustomError from '../../common/service/CustomError';
import { GET_USER_INFO } from '../../common/service/serviceEndPoints';

// post api- timezone & phone number :TODO
export const setUserDetails = async (data: any): Promise<any> => {
  try {
    const response = await patch(GET_USER_INFO, data);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(
        'An unknown error occurred, Please try again later.',
        500,
        ''
      );
    }
  }
};
