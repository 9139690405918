import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CustomError from '../../../common/service/CustomError';
import { AppThunk, RootState } from '../../../redux/store';
import { adaptRoleResponse, adaptUserResponse } from './User.adapter';
import Content from '../../../constants/ContentFile.json';
import {
  deleteUser,
  getRoles,
  getUsers,
  updateRole,
} from '../Organization.service';
import { fetchUserInfo } from '../../SplashScreen/SplashSlice';
const toastMessage = Content.organization.users;

export interface IUserState {
  showToast: boolean;
  showErorrToast: boolean;
  toastMessage: '';
  errorToastMessage: '';
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  users: IAdaptedUserResponse;
  error: CustomError | null;
  role: IRole[];
}
export interface IAdaptedUserResponse {
  activeUsers: IUser[];
  pendingUsers: IUser[];
}
export interface IUser {
  id: number;
  name: string;
  email: string;
  isActive: boolean;
  role: string;
}
export interface IRole {
  value: number | null;
  label: string;
}
const initialState: IUserState = {
  showToast: false,
  showErorrToast: false,
  toastMessage: '',
  errorToastMessage: '',
  loading: 'idle',
  users: {
    activeUsers: [],
    pendingUsers: [],
  },
  error: null,
  role: [
    {
      value: null,
      label: '',
    },
  ],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setShowToast: (state, action: PayloadAction<boolean>) => {
      state.showToast = action.payload;
    },
    setToastMessage: (state, action) => {
      state.toastMessage = action.payload;
    },

    setShowErrorToast: (state, action) => {
      state.showErorrToast = action.payload;
    },

    setErrorToastMessage: (state, action) => {
      state.errorToastMessage = action.payload;
    },

    getUsersStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getUsersSuccess(state, action) {
      state.loading = 'succeeded';
      state.users = action.payload;
      state.error = null;
    },
    getUsersFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
    },
    getRoleStarts(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getRoleSuccess(state, action) {
      state.loading = 'succeeded';
      state.role = action.payload;
      state.error = null;
    },
  },
});

export const {
  setShowToast,
  setToastMessage,
  getUsersStart,
  getUsersSuccess,
  getUsersFailure,
  getRoleStarts,
  getRoleSuccess,
  setShowErrorToast,
  setErrorToastMessage,
} = userSlice.actions;

export const fetchUsers =
  (searchKey?: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUsersStart());
      const response = await getUsers(searchKey);
      const users = adaptUserResponse(response);
      dispatch(getUsersSuccess(users));
      dispatch(fetchUserInfo());

    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getUsersFailure(error));
      } else {
        dispatch(getUsersFailure(new CustomError('Unknown error')));
      }
    }
  };

export const fetchRoles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getRoleStarts());
    const response = await getRoles();
    const roles = adaptRoleResponse(response);
    dispatch(getRoleSuccess(roles));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getUsersFailure(error));
    } else {
      dispatch(getUsersFailure(new CustomError('Unknown error')));
    }
  }
};

export const updateUserRole =
  (roleID: number, userID: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await updateRole(roleID, userID);
      dispatch(fetchUsers());
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getUsersFailure(error));
      } else {
        dispatch(getUsersFailure(new CustomError('Unknown error')));
      }
    }
  };

export const deleteExistingUser =
  (userID: number | null): AppThunk =>
  async (dispatch) => {
    try {
      const response = await deleteUser(userID);
      dispatch(setShowToast(true));
      dispatch(setToastMessage(toastMessage.userDeleteToastMsg));
      dispatch(fetchUsers());
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getUsersFailure(error));
      } else {
        dispatch(getUsersFailure(new CustomError('Unknown error')));
      }
    }
  };

export const selectUsers = (state: RootState) => state.organizationUsers.users;
export const rolesList = (state: RootState) => state.organizationUsers.role;
export const selectUsersLoading = (state: RootState) =>
  state.organizationUsers.loading;
export const selectUsersError = (state: RootState) =>
  state.organizationUsers.error;
export default userSlice.reducer;
