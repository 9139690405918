import React from 'react';

interface ToggleSwitchProps {
  isOn: boolean | undefined;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isOn,
  onChange,
  disabled = false,
}) => {
  return (
    <div>
      <input
        type="checkbox"
        checked={isOn}
        onChange={(e) => onChange(e.target.checked)}
        className={`toggle toggle-sm ${
          isOn
            ? `
           [--tglbg:#7C3AED] bg-neutral-50 hover:bg-neutral-50 border-primary-600`
            : disabled
              ? `[--tglbg:#E4E4E7] bg-neutral-400  border-neutral-200 pointer-events-none`
              : `[--tglbg:#E4E4E7] bg-neutral-0  border-neutral-200 hover:bg-neutral-0`
        }`}
      />
    </div>
  );
};

export default ToggleSwitch;
