import React from 'react';
interface ICardProps {
  children: React.ReactNode;
}

const Card = (props: ICardProps) => {
  return (
    <div className="bg-neutral-0 border border-neutral-200 px-5 py-4 rounded">
      {props.children}
    </div>
  );
};

export default Card;
