import { EventHandler, MouseEventHandler, useEffect, useState } from 'react';
import { Cancel, ErrorIcon, Information, Warning } from '../../assets/icons';
import { Success } from '../../assets/icons';
export interface ToastProps {
  Message: string;
  type: 'information' | 'success' | 'warning' | 'error';
  showToast: boolean;
  cancelToast: () => void;
}

const alertStyles = {
  information: {
    background: 'bg-primary-600',
    icon: <Information />,
    iconColor: 'text-primary-200',
  },
  success: {
    background: 'bg-secondary-600',
    icon: <Success />,
    iconColor: 'text-secondary-200',
  },
  warning: {
    background: 'bg-warning-600',
    icon: <Warning />,
    iconColor: 'text-warning-200',
  },
  error: {
    background: 'bg-error-600',
    icon: <ErrorIcon className="text-neutral-0 text-2xl" />,
    iconColor: 'text-error-200',
  },
};
const Toast = (props: ToastProps) => {
  const currentStyle = alertStyles[props.type];

  useEffect(() => {
    const clearToast = setTimeout(() => {
      props.cancelToast();
    }, 3000);
    return () => clearTimeout(clearToast);
  }, [props.showToast]);

  return (
    <div>
      {props.showToast && (
        <div className="toast toast-center md:w-3/5 lg:pl-56">
          <div
            className={`alert rounded flex ${currentStyle.background} text-zinc-50 md:h-10 p-2 text-base justify-between`}
          >
            <div className="flex items-center md:gap-2">
              <div>{currentStyle.icon}</div>
              <span className="ml-2">{props.Message}</span>
            </div>
            <button className="mr-2 " onClick={props.cancelToast}>
              <Cancel className={`${currentStyle.iconColor}`} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Toast;
