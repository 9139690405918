import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../redux/store';
import {
  getUserInfoFailure,
  getUserInfoStart,
  getUserInfoSuccess,
} from '../SplashScreen/SplashSlice';
import { setUserDetails } from './CustomerSettings.service';
import CustomError from '../../common/service/CustomError';
import { adaptUserDetailsResponse } from '../SplashScreen/SplashScreen.adapter';

export interface ICustomerInfo {
  phoneNumber: number | string;
  phoneNumberError: string;
  timezone: string;
  timezoneError: string;
  success:boolean
}
const initialState: ICustomerInfo = {
  phoneNumber: '',
  phoneNumberError: '',
  timezone: '',
  timezoneError: '',
  success:false
};
const UserInfo = createSlice({
  name: 'customerInfo',
  initialState,
  reducers: {
    setPhoneNumber: (state, action: PayloadAction<string | number>) => {
      state.phoneNumber = action.payload;
    },
    setPhoneNumberError: (state, action: PayloadAction<string>) => {
      state.phoneNumberError = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setTimezoneError: (state, action: PayloadAction<string>) => {
      state.timezoneError = action.payload;
    },
    setSuccess:(state,action)=>{
      state.success=action.payload
    },
    reset: () => initialState,
  },
});

// API call for setting mobile and timezone :TODO
export const setUserData =
  (data: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUserInfoStart());
      const response = await setUserDetails(data);
      const userDetails = adaptUserDetailsResponse(response.data);
      dispatch(getUserInfoSuccess(userDetails));
      dispatch(setSuccess(true))
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getUserInfoFailure(error));
      } else {
        dispatch(getUserInfoFailure(new CustomError('Unknown error')));
      }
    }
  };
export const {
  setPhoneNumber,
  setPhoneNumberError,
  setTimezone,
  setTimezoneError,
  setSuccess
} = UserInfo.actions;
export default UserInfo.reducer;
