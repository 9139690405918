import { Button, Input, ThemeCard, Toast, UploadButton } from '@kamet/common';
import Avatar from '@kamet/common/src/components/Avatar/Avatar';
import { MouseEventHandler, KeyboardEventHandler } from 'react';
import Content from '../../../constants/ContentFile.json';
import withPermissionFlag from '../../../common/hoc/permissionFlag/withPermissionFlag';
import { CAN_EDIT_ORGANIZATION } from '../../../common/hoc/permissionFlag/permissionFlag';
interface ISettingProps {
  onUpload?: React.ChangeEventHandler<HTMLInputElement>;
  inputValue: string;
  costValue: string;
  costErrorMessage: string;
  errorMessage: string;
  imageValue: string;
  profileImage: string;
  teamNamefirstLetter: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onChangeName: React.ChangeEventHandler<HTMLInputElement>;
  onChangeCost: React.ChangeEventHandler<HTMLInputElement>;
  onClickSave: MouseEventHandler<HTMLButtonElement>;
  showToast: boolean;
  toastMessage: string;
  cancelToast: () => void;
  imageErrorMsg: string;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const staticContent = Content.organization.settings;
const PermissionFlaggedInput = withPermissionFlag(Input);
const PermissionFlaggedButton = withPermissionFlag(Button);
const PermissionFlaggedUploadButton = withPermissionFlag(UploadButton);
const SettingsDetails = (props: ISettingProps) => {
  return (
    <>
      <ThemeCard>
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-7">
            <h3 className="text-md font-semibold text-neutral-950">
              {staticContent.title}
            </h3>
            <p className="text-base text-neutral-500 font-neutral mt-1">
              {staticContent.description}
            </p>
            <div className="mt-4">
              <PermissionFlaggedInput
                label={staticContent.teamNameLabel}
                placeholder={staticContent.teamNamePlaceholder}
                value={props.inputValue}
                onchange={props.onChangeName}
                errors={props.errorMessage ? true : false}
                errorMessage={props.errorMessage}
                variant={props.errorMessage ? 'danger' : 'primary'}
                permissionName={CAN_EDIT_ORGANIZATION}
                action="greyout"
              />
            </div>

            <div className="mt-4">
              <PermissionFlaggedInput
                label={staticContent.costPerMinuteLabel}
                placeholder={staticContent.costPerMinutePlaceholder}
                value={props.costValue}
                type="number"
                onchange={props.onChangeCost}
                errors={props.costErrorMessage ? true : false}
                errorMessage={props.costErrorMessage}
                variant={props.costErrorMessage ? 'danger' : 'primary'}
                permissionName={CAN_EDIT_ORGANIZATION}
                action="greyout"
                minimumValue={0}
                onKeyDown={props.onKeyDown}
              />
            </div>
            <div className="mt-3">
              <label className="text-md text-neutral-950 font-medium">
                {staticContent.teamImageLabel}
                <span className="text-md text-neutral-400 font-medium ml-1">
                  {staticContent.optionalText}
                </span>
              </label>
              <div className="flex gap-3 mt-2">
                <Avatar>
                  {props.profileImage ? (
                    <img
                      src={props.profileImage}
                      alt="Profile image"
                      className="object-cover h-full w-full"
                    />
                  ) : (
                    props.teamNamefirstLetter
                  )}
                </Avatar>
                {props.profileImage != '' && (
                  <>
                    <PermissionFlaggedUploadButton
                      onUpload={props.onUpload}
                      permissionName={CAN_EDIT_ORGANIZATION}
                      action="hide"
                    >
                      {staticContent.changeButtonText}
                    </PermissionFlaggedUploadButton>
                    <PermissionFlaggedButton
                      variant="secondary"
                      customWidth="w-20"
                      onClick={props.onClick}
                      permissionName={CAN_EDIT_ORGANIZATION}
                      action="hide"
                    >
                      {staticContent.removeButtonText}
                    </PermissionFlaggedButton>
                  </>
                )}
                {props.profileImage === '' && (
                  <PermissionFlaggedUploadButton
                    onUpload={props.onUpload}
                    permissionName={CAN_EDIT_ORGANIZATION}
                    action="hide"
                  >
                    {staticContent.uploadButtonText}
                  </PermissionFlaggedUploadButton>
                )}
              </div>
              <span className=" text-base font-medium text-error-700">
                {props.imageErrorMsg}
              </span>
              <div className="mt-4">
                <PermissionFlaggedButton
                  variant={props.imageErrorMsg === '' ? 'primary' : 'disable'}
                  onClick={props.onClickSave}
                  permissionName={CAN_EDIT_ORGANIZATION}
                  action="hide"
                >
                  {staticContent.SaveButtonText}
                </PermissionFlaggedButton>
              </div>
            </div>
          </div>
        </div>
      </ThemeCard>
      <Toast
        showToast={props.showToast}
        Message={props.toastMessage}
        cancelToast={props.cancelToast}
        type="success"
      />
    </>
  );
};
export default SettingsDetails;
