import { del, get, options, post } from '../../common/service/apiClient';
import axios from 'axios';
import {
  EVENT_TRIGGER,
  GET_PROVIDERS,
} from '../../common/service/serviceEndPoints';
import CustomError from '../../common/service/CustomError';
import constants from '../../constants/ContentFile.json';
const staticContents = constants.providerIntegration;
// get provider list
export const getProviders = async (): Promise<any> => {
  try {
    const response = await get<any>(GET_PROVIDERS);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(`${staticContents.getProviderError}`, 500, '');
    }
  }
};
// get credentials list
export const getProviderCredentialsDetails = async (
  url: string
): Promise<any> => {
  try {
    const response = await options<any>(url);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(`${staticContents.getCredentialsError}`, 500, '');
    }
  }
};

// API for event auto simulate

// post credentials to  integrate provider
export const activateProvider = async (
  url: string,
  data: any
): Promise<any> => {
  try {
    const response = await post(url, data);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.request.message,
        error.response.status,
        error.response.data,
        'integrator',
        'enableIntegrate',
        true
      );
    } else {
      throw new CustomError(
        `${staticContents.integrateProviderError}`,
        500,
        ''
      );
    }
  }
};

// disable providers
export const disableProvider = async (url: string): Promise<any> => {
  try {
    const response = await del(url);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(`${staticContents.disableProviderError}`, 500, '');
    }
  }
};

// auto simualate events
export const simualteEvent = async (data: any): Promise<any> => {
  try {
    const response = await post(EVENT_TRIGGER, data);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(
        error.message,
        error.response.status,
        error.response.data
      );
    } else {
      throw new CustomError(`${staticContents.getProviderError}`, 500, '');
    }
  }
};
