import {
  ICustomerInfo,
  setPhoneNumberError,
  setTimezoneError,
} from './CustomerSettings.slice';
import { isValidValue } from '@kamet/common';
import constants from '../../constants/ContentFile.json';

const staticContents = constants.personalInfo;

// Validate empty value
const isEmpty = (value: string) => {
  const error = value?.trim() === '' ? ' required' : '';
  return error === ''; // Return true if no error
};
const isValidPhoneNumber = (value: string, dispatch: any) => {
  let error = '';
  if (value?.trim() === '') {
    error = staticContents.phoneRequired;
  } else if (!isValidValue(value, 'phonenumber')) {
    error = staticContents.invalidPhonenumber;
  }
  dispatch(setPhoneNumberError(error));

  return error === ''; // Return true if no error
};

const isValidData = (name: string, value: any, dispatch: any) => {
  let isValid = true;
  switch (name) {
    case 'phoneNumber':
      if (isEmpty(value)) {
        dispatch(setPhoneNumberError(''));
      } else {
        dispatch(setPhoneNumberError(staticContents.phoneRequired));
        isValid = false;
      }
      isValid = isValidPhoneNumber(value, dispatch);
      break;
    case 'timezone':
      if (isEmpty(value)) {
        dispatch(setTimezoneError(''));
      } else {
        dispatch(setTimezoneError(staticContents.timezoneRequired));
        isValid = false;
      }
      break;
    default:
      break;
  }
  return isValid;
};

const isValidForm = (personalInfo: ICustomerInfo, dispatch: any) => {
  const isValidPhoneNumber = isValidData(
    'phoneNumber',
    personalInfo.phoneNumber,
    dispatch
  );
  const isValidTimezone = isValidData(
    'timezone',
    personalInfo.timezone,
    dispatch
  );
  return isValidPhoneNumber && isValidTimezone;
};

export { isValidData, isValidForm, isEmpty, isValidPhoneNumber };
