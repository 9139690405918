import React, { SVGProps } from 'react';

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.55556 2C2.40821 2 2.26691 2.05853 2.16272 2.16272C2.05853 2.26691 2 2.40821 2 2.55556V13.4444C2 13.5918 2.05853 13.7331 2.16272 13.8373C2.26691 13.9415 2.40821 14 2.55556 14H5.66667C6.21895 14 6.66667 14.4477 6.66667 15C6.66667 15.5523 6.21895 16 5.66667 16H2.55556C1.87778 16 1.22776 15.7308 0.748505 15.2515C0.269245 14.7722 0 14.1222 0 13.4444V2.55556C0 1.87778 0.269245 1.22776 0.748505 0.748505C1.22776 0.269245 1.87778 0 2.55556 0H5.66667C6.21895 0 6.66667 0.447715 6.66667 1C6.66667 1.55228 6.21895 2 5.66667 2H2.55556ZM10.404 3.404C10.7945 3.01348 11.4277 3.01348 11.8182 3.404L15.7071 7.29289C16.0976 7.68342 16.0976 8.31658 15.7071 8.70711L11.8182 12.596C11.4277 12.9865 10.7945 12.9865 10.404 12.596C10.0135 12.2055 10.0135 11.5723 10.404 11.1818L12.5858 9H5.66667C5.11438 9 4.66667 8.55228 4.66667 8C4.66667 7.44772 5.11438 7 5.66667 7H12.5858L10.404 4.81822C10.0135 4.42769 10.0135 3.79453 10.404 3.404Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default LogoutIcon;
