import { IUser } from './User.slice';

// user list
export const adaptUserResponse = (data: any) => {
  const users = data.map((user: any) => ({
    id: user.id ?? 0,
    name: user.name ?? '-',
    isActive: user.is_active ?? false,
    email: user.email ?? '-',
    role: user.role,
    roleName: user.role_name,
    isSuperUser:user.is_superuser 
    }));
  const activeUsers = users.filter((user: IUser) => user.isActive);
  const pendingUsers = users.filter((user: IUser) => !user.isActive);
  const userList = {
    activeUsers,
    pendingUsers,
  };
  return userList;
};

// role list
export const adaptRoleResponse = (data: any) => {
  const roles = data.map((role: any) => ({
    value: role.id ?? null,
    label: role.name ?? '-',
  }));
  return roles;
};
