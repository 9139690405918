import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserDetails from './User.view';
import RouteConstants from '../../../constants/routeConstants.json';
import { setShowAddUserModal } from '../newUser/newUser.slice';
import { AppDispatch, RootState } from '../../../redux/store';
import {
  setShowToast,
  fetchUsers,
  selectUsers,
  selectUsersLoading,
  selectUsersError,
  fetchRoles,
  rolesList,
  updateUserRole,
  deleteExistingUser,
  setShowErrorToast,
} from './User.slice';
import { Loader } from '@kamet/common';
import ErrorPage from '../../../common/component/ErrorPage/Errorpage';
import { selectUserInfo } from '../../SplashScreen/SplashSlice';

const User = () => {
  const [searchkey, setSearchKey] = useState(''); // state to handle search
  const [showDeleteModal, setShowDeleteModal] = useState(false); // state to handle delete modal
  const [userID, setUserID] = useState<number>();
  const dispatch = useDispatch() as AppDispatch; // assigning useDispatch function to a variable

  const users = useSelector((state: RootState) => selectUsers(state));
  const loading = useSelector((state: RootState) => selectUsersLoading(state));
  const error = useSelector((state: RootState) => selectUsersError(state));
  const roles = useSelector((state: RootState) => rolesList(state));
  const currentUserDetails = useSelector((state: RootState) =>
    selectUserInfo(state)
  );

  const userDetails = useSelector(
    (state: RootState) => state.organizationUsers
  );

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchRoles());
  }, [dispatch]);

  // function to get search key
  const onHandleSearch = (e: any) => {
    const search = e.target.value;
    setSearchKey(search);
    if (search === '') {
      dispatch(fetchUsers());
    }
  };
  // function to clear search key
  const onhandleClearSearch = () => {
    setSearchKey('');
    dispatch(fetchUsers());
  };

  // common function to check search or new user
  const PrimaryButtonClick = () => {
    if (searchkey) {
      onClickSearchUser();
    } else {
      onClickAddNewUser();
    }
  };

  const onClickSearchUser = () => {
    dispatch(fetchUsers(searchkey));
  };

  const onChangeUserRole = (e: any, userId: number) => {
    const roleID = Number(e.target.value);
    const userID = userId;
    dispatch(updateUserRole(roleID, userID));
  };

  // function on new user button - to open add user modal
  const onClickAddNewUser = () => {
    dispatch(setShowAddUserModal(true));
  };
  // on click delete icon - show remove user modal
  const oClickDeleteButton = (userId: number) => {
    setUserID(userId);
    setShowDeleteModal(true);
  };
  // function to close remove user modal
  const onCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // function to remove user - on clicking remove nutton inside modal
  const onClickRemoveButton = () => {
    setShowDeleteModal(false);
    dispatch(deleteExistingUser(userID ? userID : null));
  };
  // onClick cancel toast
  const onClickCancelToast = () => {
    dispatch(setShowToast(false));
  };

  // const cancel error toast
  const onClickCancelErrorToast = () => {
    dispatch(setShowErrorToast(false));
  };
  // error page action button
  const ErrorActionClick = () => {
    window.location.reload();
  };
  if (loading === 'pending') return <Loader />;
  if (error)
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Navigate to Dashboard"
        isButtonVisible={true}
        action={ErrorActionClick}
      />
    );

  return (
    <>
      <UserDetails
        roleOptions={roles}
        activeUser={users ? users.activeUsers : []}
        pendingUser={users ? users.pendingUsers : []}
        data={users ? users.activeUsers : []}
        searchValue={searchkey}
        onSearch={(e) => onHandleSearch(e)}
        clearSearchKey={onhandleClearSearch}
        onClick={PrimaryButtonClick}
        onClickDelete={oClickDeleteButton}
        // Delete modal
        deleteUserModal={showDeleteModal}
        onCloseDeleteModal={onCloseDeleteModal}
        onclickRemove={onClickRemoveButton}
        // toast
        showToast={userDetails.showToast}
        toastMessage={userDetails.toastMessage}
        cancelToast={onClickCancelToast}
        // dropdown
        onChangeRole={(e: any, userId: number) => onChangeUserRole(e, userId)}
        currentUser={currentUserDetails}
        // Error toast
        showErrorToast={userDetails.showErorrToast}
        errorToastMessage={userDetails.errorToastMessage}
        closeErrorToast={onClickCancelErrorToast}
      />
    </>
  );
};

export default User;
