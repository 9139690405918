import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUserInfo {
  fullName: string;
  fullNameError: string;
  phoneNumber: string;
  phoneNumberError: string;
  timezone: string;
  timezoneError: string;
}
const initialState: IUserInfo = {
  fullName: '',
  fullNameError: '',
  phoneNumber: '',
  phoneNumberError: '',
  timezone: '',
  timezoneError: '',
};
const UserInfo = createSlice({
  name: 'personalInfo',
  initialState,
  reducers: {
    setFullName: (state, action: PayloadAction<string>) => {
      state.fullName = action.payload;
    },
    setFullNameError: (state, action: PayloadAction<string>) => {
      state.fullNameError = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setPhoneNumberError: (state, action: PayloadAction<string>) => {
      state.phoneNumberError = action.payload;
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setTimezoneError: (state, action: PayloadAction<string>) => {
      state.timezoneError = action.payload;
    },
    reset: () => initialState,
  },
});
export const {
  setFullName,
  setFullNameError,
  setPhoneNumber,
  setPhoneNumberError,
  setTimezone,
  setTimezoneError,
} = UserInfo.actions;
export default UserInfo.reducer;
