import { Button, ThemeCard } from '@kamet/common';
import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../../../constants/routeConstants.json';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { selectUserInfo } from '../../SplashScreen/SplashSlice';
import withPermissionFlag from '../../../common/hoc/permissionFlag/withPermissionFlag';
import {
  ADD_PROVIDER,
  ADD_USER,
} from '../../../common/hoc/permissionFlag/permissionFlag';

interface IIntegrationEnableProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
  pageContent: any;
}

const IntegrationEnableComponent = (props: IIntegrationEnableProps) => {
  const navigate = useNavigate();
  const details = props.pageContent;
  const userDetails = useSelector((state: RootState) => selectUserInfo(state));

  const InviteNewUserButton = () => (
    <Button
      onClick={() => navigate(RouteConstants.ORGANIZATION)}
      variant="secondary"
    >
      {details.addUserText}
    </Button>
  );

  const EnableIntegrationButton = () => (
    <Button onClick={props.onClick} variant="primary">
      {details.enableButtonText}
    </Button>
  );

  const InviteUserButtonComponent = withPermissionFlag(InviteNewUserButton);
  const EnableIntegrationButtonComponent = withPermissionFlag(
    EnableIntegrationButton
  );
  return (
    <>
      <ThemeCard>
        <div className="grid grid-cols-12">
          <div className="col-span-12 xl:col-span-7">
            <h1 className="text-neutral-950 text-lg font-semibold">
              {details.welcome} {userDetails.userDetails.name},
            </h1>
            <p className="text-neutral-500 text-base font-normal mt-2">
              {details.welcomeText}
            </p>
            <div className="mt-4 flex space-x-4">
              <EnableIntegrationButtonComponent
                permissionName={ADD_PROVIDER}
                action={'greyout'}
              />
              <InviteUserButtonComponent
                permissionName={ADD_USER}
                action={'greyout'}
              />
            </div>
          </div>
        </div>
      </ThemeCard>
    </>
  );
};

export default IntegrationEnableComponent;
