import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CustomError from '../../common/service/CustomError';
import { AppThunk, RootState } from '../../redux/store';
import { adaptUserDetailsResponse, adaptRolesNPermissionsResponse } from './SplashScreen.adapter';
import { IPermission, IUserProfileDetails } from './SplashscreenSettings';
import { enableNotification, getRolesNPermission, getUserInfo } from './SplashScreen.service';
export interface UserState {
  userDetails: IUserProfileDetails;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed' | string;
  error: CustomError | null;
  isLogin: boolean;
  rolesNPermissions: IPermission[]
}

const initialState: UserState = {
  userDetails: {
    name: '',
    role: '',
    email: '',
    phoneNumber: '',
    timezone: '',
    isEmailNotification: false,
    isSmsNotification: false,
    imageUrl: '',
    isSuperUser: false,
    businessName:''
  },
  rolesNPermissions: [],
  loading: 'idle',
  error: null,
  isLogin: false,
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    getUserInfoStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getUserInfoSuccess(state, action: PayloadAction<IUserProfileDetails>) {
      state.userDetails = {
        ...action.payload,
      };
      state.loading = 'succeeded';
      state.error = null;
      state.isLogin = true;
    },
    getUserInfoFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
      state.isLogin = false
    },
    getRolesAndPermissionSuccess(state, action: PayloadAction<IPermission[]>) {
      state.rolesNPermissions = action.payload;
    }
  },
});

export const { getUserInfoStart, getUserInfoSuccess, getUserInfoFailure, getRolesAndPermissionSuccess } =
  userDetailsSlice.actions;

// API call
export const fetchUserInfo = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserInfoStart());
    const response = await getUserInfo();
    const userDetails = adaptUserDetailsResponse(response.data);
     if(!userDetails.isSuperUser) {
      const permissionResponse = await getRolesNPermission();
      const permissions = adaptRolesNPermissionsResponse(permissionResponse)
      dispatch(getRolesAndPermissionSuccess(permissions))
    }
    dispatch(getUserInfoSuccess(userDetails));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getUserInfoFailure(error));
    } else {
      dispatch(getUserInfoFailure(new CustomError('Unknown error')));
    }
  }
};

// API call for enable notifications
export const notificationChange = (data:any): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserInfoStart());
    const response = await enableNotification(data);
    const userDetails = adaptUserDetailsResponse(response.data);
    dispatch(getUserInfoSuccess(userDetails));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getUserInfoFailure(error));
    } else {
      dispatch(getUserInfoFailure(new CustomError('Unknown error')));
    }
  }
};
export const selectUserInfo = (state: RootState) => state.userDetails;
export const selectUserInfoLoading = (state: RootState) =>
  state.userDetails.loading;
export const selectUserInfoError = (state: RootState) =>
  state.userDetails.error;

export default userDetailsSlice.reducer;
