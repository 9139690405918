import React from 'react';
import { LoaderIcon } from '../../assets/icons';

const Loader: React.FC = () => {
  return (
    <div className="bg-neutral-50 grid place-items-center h-screen">
      <LoaderIcon/>
    </div>
  );
};

export default Loader;
