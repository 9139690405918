import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../redux/store';
import CustomError from '../../../common/service/CustomError';
import { getRoles, addNewUser } from '../Organization.service';
import { adaptRoleResponse } from './newUser.adapter';
import {
  fetchUsers,
  setErrorToastMessage,
  setShowErrorToast,
  setShowToast,
  setToastMessage,
} from '../users/User.slice';
import Content from '../../../constants/ContentFile.json';

const staticContent = Content.organization.newUserContent;
interface INewUserState {
  email: string;
  emailErrorMsg: string;
  userRole: number | null;
  roleErrorMsg: string;
  showAddUserModal: boolean;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: CustomError | null;
  role: IRole[];
}
export interface IRole {
  value: number | null;
  label: string;
}

const initialState: INewUserState = {
  email: '',
  emailErrorMsg: '',
  userRole: null,
  roleErrorMsg: '',
  showAddUserModal: false,
  loading: 'idle',
  error: null,
  role: [
    {
      value: null,
      label: '',
    },
  ],
};

const newUserSlice = createSlice({
  name: 'newUser',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setEmailErrorMsg: (state, action: PayloadAction<string>) => {
      state.emailErrorMsg = action.payload;
    },
    setUserRole: (state, action: PayloadAction<any>) => {
      state.userRole = action.payload;
    },
    setRoleErrorMsg: (state, action: PayloadAction<string>) => {
      state.roleErrorMsg = action.payload;
    },
    setShowAddUserModal: (state, action) => {
      state.showAddUserModal = action.payload;
    },
    reset: (state, action) => {
      state.email = action.payload;
      state.emailErrorMsg = action.payload;
      state.userRole = action.payload;
      state.roleErrorMsg = action.payload;
    },
    getRoleStarts(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getRoleSuccess(state, action) {
      state.loading = 'succeeded';
      state.role = action.payload;
      state.error = null;
    },
    getRoleFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
    },
    addUserStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    addUserSuccess(state) {
      state.loading = 'succeeded';
      state.error = null;
    },
    addUserFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
    },
  },
});

export const {
  setEmail,
  setEmailErrorMsg,
  setUserRole,
  setRoleErrorMsg,
  setShowAddUserModal,
  reset,
  getRoleStarts,
  getRoleSuccess,
  getRoleFailure,
  addUserStart,
  addUserSuccess,
  addUserFailure,
} = newUserSlice.actions;

export const fetchRoles = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getRoleStarts());
    const response = await getRoles();
    const roles = adaptRoleResponse(response);
    dispatch(getRoleSuccess(roles));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getRoleFailure(error));
    } else {
      dispatch(getRoleFailure(new CustomError('Unknown error')));
    }
  }
};

export const inviteNewUser =
  (email: string, role: number | null): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(addUserStart());
      await addNewUser(email, role);
      dispatch(setShowToast(true));
      dispatch(setToastMessage(staticContent.userAddedToastMsg));
      dispatch(fetchUsers());
      dispatch(addUserSuccess());
    } catch (error) {
      if (error instanceof CustomError) {
        if (error.statusCode === 400) {
          dispatch(setShowErrorToast(true));
          dispatch(setErrorToastMessage(staticContent.userAddErrorToastMsg));
        }
        dispatch(addUserFailure(error));
      } else {
        dispatch(addUserFailure(new CustomError('Unknown error')));
      }
    }
  };

export const rolesList = (state: RootState) => state.newUser.role;
export const addUserLoading = (state: RootState) => state.newUser.loading;
export const addUserError = (state: RootState) => state.newUser.error;

export default newUserSlice.reducer;
