import Settings from '../settings/Settings.page';
import User from '../users/User.page';
import Content from '../../../constants/ContentFile.json';

const staticContent = Content.organization.tabContent;

const Tabs = () => {
  return (
    <>
      <div role="tablist" className="tabs tabs-lifted">
        <input
          type="radio"
          name={staticContent.tabName}
          role={staticContent.role}
          className="tab font-medium text-neutral-500 text-base"
          aria-label={staticContent.userLabel}
          defaultChecked
        />
        <div
          role="tabpanel"
          className="tab-content border-t border-neutral-200 pt-4"
        >
          <User />
        </div>

        <input
          type="radio"
          name={staticContent.tabName}
          role={staticContent.role}
          className="tab font-medium text-neutral-500 text-base"
          aria-label={staticContent.settingsLabel}
        />
        <div
          role="tabpanel"
          className="tab-content border-t border-neutral-200 pt-4"
        >
          <Settings />
        </div>
      </div>
    </>
  );
};

export default Tabs;
