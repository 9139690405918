import React, { SVGProps } from 'react';

// Dashboard icon for sidemenu

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.7955 0.329505C9.35616 -0.109835 8.64384 -0.109835 8.2045 0.329505L0.329505 8.2045C-0.109835 8.64384 -0.109835 9.35616 0.329505 9.7955C0.768845 10.2348 1.48116 10.2348 1.9205 9.7955L2.25 9.46599V16.875C2.25 17.4963 2.75368 18 3.375 18H5.625C6.24632 18 6.75 17.4963 6.75 16.875V14.625C6.75 14.0037 7.25368 13.5 7.875 13.5H10.125C10.7463 13.5 11.25 14.0037 11.25 14.625V16.875C11.25 17.4963 11.7537 18 12.375 18H14.625C15.2463 18 15.75 17.4963 15.75 16.875V9.46599L16.0795 9.7955C16.5188 10.2348 17.2312 10.2348 17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L9.7955 0.329505Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default DashboardIcon;
