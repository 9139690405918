import { createSlice } from '@reduxjs/toolkit';

interface IlogoutSliceprops {
  showLogoutModal: boolean;
}

const initialState: IlogoutSliceprops = {
  showLogoutModal: false,
};

const LogoutSlice = createSlice({
  name: 'Logout',
  initialState,
  reducers: {
    setShowlogoutModal: (state, action) => {
      state.showLogoutModal = action.payload;
    },
  },
});

export const { setShowlogoutModal } = LogoutSlice.actions;

export default LogoutSlice.reducer;
