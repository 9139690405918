import { get, patch } from '../../common/service/apiClient';
import axios from 'axios';
import {GET_ROLES_N_PERMISSIONS, GET_USER_INFO } from '../../common/service/serviceEndPoints';
import CustomError from '../../common/service/CustomError';

export const getUserInfo= async (): Promise<any> => {
  try {
    const response = await get<any>(GET_USER_INFO);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(error.message, error.response.status, error.response.data, 'splashScreen', 'me');
    } else {
      throw new CustomError('An unknown error occurred, Please try again later.', 500, '');
    }
  }
};
// enable or disable notifications
export const enableNotification= async (data:any): Promise<any>  => {
  try {
    const response = await patch(GET_USER_INFO,data);
    return response;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(error.message, error.response.status, error.response.data);
    } else {
      throw new CustomError('An unknown error occurred, Please try again later.', 500, '');
    }
  }
};

export const getRolesNPermission = async (): Promise<any> => {
  try {
    const response = await get<any>(GET_ROLES_N_PERMISSIONS);
    return response&&response.data&&response.data.results;
  } catch (error) {
    // Handle error and throw a custom error or rethrow
    if (axios.isAxiosError(error) && error.response) {
      // Customize the error message or structure as needed
      throw new CustomError(error.message, error.response.status, error.response.data);
    } else {
      throw new CustomError('An unknown error occurred, Please try again later.', 500, '');
    }
  }
}
