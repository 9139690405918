import React, { SVGProps } from 'react';

const Delete = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 20"
        fill="currentColoe"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.60033 3.20039C6.60033 3.10064 6.66027 2.9172 6.83866 2.73881C7.01704 2.56043 7.20049 2.50049 7.30023 2.50049H10.7C10.7998 2.50049 10.9832 2.56043 11.1616 2.73881C11.34 2.9172 11.3999 3.10064 11.3999 3.20039V3.90029H6.60033V3.20039ZM13.3999 3.20039V3.90029H14.9498H16.6497C17.202 3.90029 17.6497 4.348 17.6497 4.90029C17.6497 5.45257 17.202 5.90029 16.6497 5.90029H15.9498V16.7996C15.9498 17.5498 15.5847 18.2163 15.1257 18.6754C14.6666 19.1344 14.0001 19.4995 13.2499 19.4995H4.75038C4.00018 19.4995 3.33367 19.1344 2.8746 18.6754C2.41552 18.2163 2.05049 17.5498 2.05049 16.7996V5.90029H1.35059C0.798301 5.90029 0.350586 5.45257 0.350586 4.90029C0.350586 4.348 0.798301 3.90029 1.35059 3.90029H3.05049H4.60033V3.20039C4.60033 2.45018 4.96537 1.78368 5.42445 1.3246C5.88352 0.865523 6.55003 0.500488 7.30023 0.500488H10.7C11.4502 0.500488 12.1167 0.865523 12.5758 1.3246C13.0349 1.78368 13.3999 2.45018 13.3999 3.20039ZM7 7.88892C7.55229 7.88892 8 8.33663 8 8.88892V14.8889C8 15.4412 7.55229 15.8889 7 15.8889C6.44771 15.8889 6 15.4412 6 14.8889V8.88892C6 8.33663 6.44771 7.88892 7 7.88892ZM12 8.88892C12 8.33663 11.5523 7.88892 11 7.88892C10.4477 7.88892 10 8.33663 10 8.88892V14.8889C10 15.4412 10.4477 15.8889 11 15.8889C11.5523 15.8889 12 15.4412 12 14.8889V8.88892Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default Delete;
