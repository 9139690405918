import { createSlice } from '@reduxjs/toolkit';

interface ImenuListProps {
  label?: string;
  path?: string;
  icon?: string;
}

interface IinitialState {
  menuList: ImenuListProps[];
}

const initialState: IinitialState = {
  menuList: [],
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setmenuList: (state, action) => {
      state.menuList = action.payload;
    },
  },
});
export const { setmenuList } = permissionSlice.actions;

export default permissionSlice.reducer;
