import {
  setCostErrorMessage,
  setTeamNameErrorMsg,
  setTeamNameFirstLetter,
} from './Settings.slice';
import Content from '../../../constants/ContentFile.json';
import { isValidValue } from '@kamet/common';
const errorMessage = Content.organization.settings;

// Special character validation
// Special character validation
const isValidName = (name: string, dispatch: any) => {
  const trimmedName = name.trim();
  if (trimmedName === '') {
    dispatch(setTeamNameErrorMsg(errorMessage.TeamNameErrorMsg));
    dispatch(setTeamNameFirstLetter(''));
    return false; // Changed to false to prevent form submission
  } else {
    const isValid = isValidValue(name, 'teamName');
    if (isValid) {
      dispatch(setTeamNameErrorMsg(''));
      const firstLetter = name.charAt(0).toUpperCase();
      dispatch(setTeamNameFirstLetter(firstLetter));
    } else {
      dispatch(setTeamNameErrorMsg('Invalid name'));
    }
    return isValid;
  }
};

const isValidCostValue = (cost: string, dispatch: any) => {
  if (cost === '') {
    dispatch(setCostErrorMessage(errorMessage.costErrorMessage));
    return false; // Changed to false to prevent form submission
  } else if (cost === '0') {
    dispatch(setCostErrorMessage(errorMessage.costMoreThanZeroMessage));
  } else {
    dispatch(setCostErrorMessage(''));
  }
};

export { isValidName, isValidCostValue };
