import { AlertModal, Input, Toast } from '@kamet/common';
import IntegrationCard from '../../common/component/IntegrationCard/IntegrationCard';
import Constant from '../../constants/ContentFile.json';
import { KMProvider } from './KMProvider';
import NoIntegratorsCard from './component/NoIntegratorsCard';

interface IIntegrationView {
  onChange: (id: string) => void;
  data: KMProvider[];
  isModalopen: boolean;
  isRemoveModalOpen: boolean;
  inputErrors: any;
  onInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    maxLength: number
  ) => void;
  onModalSubmit: () => void;
  onModalClose: () => void;
  removeButtonClick: () => void;
  onRemoveModalClose: () => void;
  showAlertToast: boolean;
  alertToastMessage: string;
  selectedProviderName: string;
  toastType: 'success' | 'error' | 'information' | 'warning';
  cancelToast: () => void;
  inputs: [];
}

const IntegrationsView = (props: IIntegrationView) => {
  const staticContents = Constant.providerIntegration;
  return (
    <div className=" h-[calc(100%-1rem)] md:h-screen lg:h-[calc(100%-1rem)] relative">
      <div>
        <h3 className="text-2xl text-neutral-950 font-semibold py-4">
          {staticContents?.integrations}
        </h3>
      </div>

      {props.data?.length === 0 ? (
        <NoIntegratorsCard />
      ) : (
        <div className="grid  grid-cols-1  md:grid-cols-3 gap-4">
          {props?.data.map((item) => (
            <IntegrationCard
              key={item?.id}
              name={item.name}
              learnMore={item.info}
              onChange={() => props.onChange(item?.id)}
              isOn={item.isEnabled}
            />
          ))}
        </div>
      )}

      {/* provider enable modal */}
      <AlertModal
        isOpen={props.isModalopen}
        closeButtonText={staticContents.alertClose}
        submitButtonText={staticContents.alertSubmit}
        title={`${props.selectedProviderName} Authentication`}
        onClose={props.onModalClose}
        onSubmit={props.onModalSubmit}
      >
        <div className="flex flex-col gap-2">
          <div className="text-neutral-950 text-base pb-1">
            Please enter your {props.selectedProviderName} credentials to
            continue.
          </div>

          {props?.inputs &&
            props.inputs?.map((item: any) => {
              return (
                <>
                  <Input
                    label={item.label}
                    placeholder={item.label}
                    value={item.value}
                    name={item.name}
                    onchange={(e) =>
                      props.onInputChange(
                        e,
                        item.maxLength ? item.maxLength : 0
                      )
                    }
                    errors={props.inputErrors[item.name]} // Check for errors dynamically
                    errorMessage={props.inputErrors[item.name]} // Get the error message dynamically
                    variant={
                      props.inputErrors[item.name] ? 'danger' : 'primary'
                    }
                    required={true}
                    type={item.type}
                  />
                </>
              );
            })}
        </div>
      </AlertModal>
      {/* provider disable modal */}
      <AlertModal
        isOpen={props.isRemoveModalOpen}
        closeButtonText={staticContents.alertClose}
        submitButtonText={staticContents.remove}
        title={staticContents.removeTitle}
        onClose={props.onRemoveModalClose}
        onSubmit={props.removeButtonClick}
        submitButtonVariant={true}
      >
        <div className="flex flex-col gap-2">
          <div className="text-neutral-950 text-base pb-1">
            {staticContents.removeDescription}
          </div>
        </div>
      </AlertModal>
      {/* alert success toast */}

      <Toast
        showToast={props.showAlertToast}
        Message={props.alertToastMessage}
        cancelToast={props.cancelToast}
        type={props.toastType}
      />
    </div>
  );
};

export default IntegrationsView;
