import {
  KAMDashboardModel,
  Analytics,
  Risk,
  Event,
  DailyRisks,
  Risks,
} from './KAMDashboardModel';

export const adaptDashboardResponse = (data: any) => {
  const adaptAnalytics = (analytics: any[]): Analytics[] => {
    return analytics.map((item) => ({
      title: item?.title,
      value: item?.value,
      deviation: item?.deviation,
      trend: item?.trend as 'up' | 'down',
    }));
  };

  const adaptPerformanceRisk = (risk: any): any => {
    return {
      totalLowRisk: risk?.total_low_risk,
      lowRiskPercentage: risk?.low_risk_percentage
        ? Number(risk?.low_risk_percentage?.toFixed(2))
        : 0,

      totalMediumRisk: risk?.total_medium_risk,
      mediumRiskPercentage: risk?.medium_risk_percentage
        ? Number(risk?.medium_risk_percentage?.toFixed(2))
        : 0,

      totalHighRisk: risk?.total_high_risk,
      highRiskPercentage: risk?.high_risk_percentage
        ? Number(risk?.high_risk_percentage?.toFixed(2))
        : 0,

      // colorCode: getColorCode(risk.title),
    };
  };
  const adaptOperationalRisk = (risk: any): any => {
    return {
      totalLowRisk: risk?.total_low_risk,
      lowRiskPercentage: risk?.low_risk_percentage
        ? Number(risk?.low_risk_percentage?.toFixed(2))
        : 0,
      totalMediumRisk: risk?.total_medium_risk,
      mediumRiskPercentage: risk?.medium_risk_percentage
        ? Number(risk?.medium_risk_percentage?.toFixed(2))
        : 0,

      totalHighRisk: risk?.total_high_risk,
      highRiskPercentage: risk?.high_risk_percentage
        ? Number(risk?.high_risk_percentage?.toFixed(2))
        : 0,

      // colorCode: getColorCode(risk.title),
    };
  };

  const adaptEvent = (event: any): any => {
    return {
      id: event?.id,
      category: event?.category,
      value: event?.value,
      date: event?.date,
      domain: event?.domain,
      event: event?.event,
    };
  };

  const adaptDailyRisks = (dailyRisksData: any): any[] => {
    return Object.entries(dailyRisksData)?.map(
      ([date, dailyRisks]: [string, any]) => ({
        date: date,
        count: dailyRisks.count,
        highRisk: dailyRisks.high_risk,
        lowRisk: dailyRisks.no_risk,
        mediumRisk: dailyRisks.medium_risk,
        time: dailyRisks.time,
        cost: dailyRisks.cost,
      })
    );
  };

  // const adaptRisks = (risks: any): any => {
  //   const risksArray = Object.keys(risks.risks).map((date) =>
  //     adaptDailyRisks(risks.risks[date], date)
  //   );
  //   return {
  //     overall: {
  //       title: risks.overall.title,
  //       risks: risks.overall,
  //     },
  //     risks: risksArray,
  //     // Object.keys(risks.risks).reduce((acc, date) => {
  //     //   acc[date] = adaptDailyRisks(risks.risks[date]);
  //     //   return acc;
  //     // }, {} as Record<string, DailyRisks>),
  //   };
  // };

  return {
    isIntegrated: data?.isIntegrated,
    analytics: adaptAnalytics(data?.analytics),
    risks: adaptDailyRisks(data?.risks),
    performanceRisk: adaptPerformanceRisk(data?.performance_risk),
    operationsRisk: adaptOperationalRisk(data?.operational_risk),
  };
};
