import { useEffect, useState } from 'react';
import { isValidEmail, isValidUserRole } from './newUser.utils';
import AddNewUser from './newUser.view';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchRoles,
  inviteNewUser,
  reset,
  rolesList,
  setEmail,
  setEmailErrorMsg,
  setRoleErrorMsg,
  setShowAddUserModal,
  setUserRole,
} from './newUser.slice';
import { AppDispatch, RootState } from '../../../redux/store';

const NewUser = () => {
  const [showDiscardModal, setShowDiscardModal] = useState(false); // state to handle discard modal
  const [roleName, setRoleName] = useState('');
  const dispatch = useDispatch() as AppDispatch; // assigning useDispatch function to a variable
  // assigning useDispatch function to a variable
  const newUserDetails = useSelector((state: RootState) => state.newUser); // get states from reducer
  const roles = useSelector((state: RootState) => rolesList(state));

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  //  email onchange function
  const onchangeEmail = (e: any) => {
    const email = e.target.value.toLowerCase();
    isValidEmail(email, dispatch);
    dispatch(setEmail(email));
  };
  // on change user role function
  const onChangeUserRole = (e: any) => {
    const role = e.target.value;
    setRoleName(role);
    isValidUserRole(role, dispatch);
    dispatch(setUserRole(Number(role)));
  };
  // on click new user modal cancel
  const onClickClosemodal = () => {
    dispatch(setShowAddUserModal(false));
    dispatch(setEmailErrorMsg(''));
    dispatch(setRoleErrorMsg(''));
    if (newUserDetails.email !== '' || newUserDetails.userRole !== null) {
      setShowDiscardModal(true);
    }
  };
  // on click discard modal close
  const onCancelDiscardModal = () => {
    dispatch(setShowAddUserModal(true));
    setShowDiscardModal(false);
  };

  // on discard - discard modal
  const onClickDiscardButton = () => {
    setShowDiscardModal(false);
    //  remove values from states
    dispatch(reset(''));
  };

  // on submiting new user - on clicking new user modal continue
  const onConintueButton = () => {
    const email = newUserDetails.email;
    const userRole = newUserDetails.userRole;
    const emailValidation = isValidEmail(email, dispatch);
    const roleValidation = isValidUserRole(userRole, dispatch);
    if (emailValidation && roleValidation) {
      dispatch(inviteNewUser(email, userRole));
      dispatch(setShowAddUserModal(false));
      //  remove values from states
      dispatch(reset(''));
      setRoleName('');
      // TODO
    }
  };
  return (
    <>
      <AddNewUser
        // email input props
        emailValue={newUserDetails.email}
        emailErrorMsg={newUserDetails.emailErrorMsg}
        onChangeEmail={(e) => onchangeEmail(e)}
        // User role dropdown props
        roleOptions={roles}
        roleValue={roleName}
        onChangeSelect={(e) => onChangeUserRole(e)}
        SelectErrorMessage={newUserDetails.roleErrorMsg}
        onCloseAddUserModal={onClickClosemodal}
        onClickContinue={onConintueButton}
        AddUserModalOpen={newUserDetails.showAddUserModal}
        // discard modal props
        showDiscardModal={showDiscardModal}
        onCancelDiscardModal={onCancelDiscardModal}
        onClickDiscard={onClickDiscardButton}
      />
    </>
  );
};
export default NewUser;
