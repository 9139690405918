import { MouseEventHandler } from 'react';
import { LogoutIcon } from '../../assets/icons';

interface ILogoutButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const LogoutButton = (props: ILogoutButtonProps) => {
  return (
    <>
      <button
        className="w-full h-10 flex items-center gap-3 rounded font-medium text-base hover:bg-primary-50 hover:text-primary-700 text-left px-4"
        onClick={props.onClick}
      >
        <LogoutIcon />
        Log Out
      </button>
    </>
  );
};

export default LogoutButton;
