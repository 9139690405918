import React, { SVGProps } from 'react';

// integration icon for sidemenu

const IntegrationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 18 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M8.69471 0.0770369C8.78847 0.0264722 8.89333 0 8.99986 0C9.10638 0 9.21124 0.0264722 9.305 0.0770369L17.6622 4.57706C17.7642 4.63215 17.8495 4.71381 17.9089 4.8134C17.9683 4.913 17.9997 5.02681 17.9997 5.14278C17.9997 5.25875 17.9683 5.37256 17.9089 5.47216C17.8495 5.57175 17.7642 5.65342 17.6622 5.7085L9.305 10.2085C9.21124 10.2591 9.10638 10.2856 8.99986 10.2856C8.89333 10.2856 8.78847 10.2591 8.69471 10.2085L0.337519 5.7085C0.235464 5.65342 0.15021 5.57175 0.0907909 5.47216C0.0313716 5.37256 0 5.25875 0 5.14278C0 5.02681 0.0313716 4.913 0.0907909 4.8134C0.15021 4.71381 0.235464 4.63215 0.337519 4.57706L8.69471 0.0770369Z"
          fill="currentColor"
        />
        <path
          d="M1.51267 7.80165L8.08528 11.3408C8.36635 11.4922 8.6806 11.5715 8.99986 11.5715C9.31911 11.5715 9.63336 11.4922 9.91443 11.3408L16.487 7.8008L17.6613 8.43423C17.7634 8.48931 17.8486 8.57098 17.9081 8.67057C17.9675 8.77016 17.9989 8.88398 17.9989 8.99995C17.9989 9.11592 17.9675 9.22973 17.9081 9.32932C17.8486 9.42892 17.7634 9.51058 17.6613 9.56566L9.30414 14.0657C9.2106 14.116 9.10605 14.1423 8.99986 14.1423C8.89366 14.1423 8.78911 14.116 8.69557 14.0657L0.338376 9.56566C0.236321 9.51058 0.151067 9.42892 0.091648 9.32932C0.0322287 9.22973 0.000857086 9.11592 0.000857086 8.99995C0.000857086 8.88398 0.0322287 8.77016 0.091648 8.67057C0.151067 8.57098 0.236321 8.48931 0.338376 8.43423L1.51267 7.80165Z"
          fill="currentColor"
        />
        <path
          d="M8.08528 15.198L1.51267 11.658L0.338376 12.2914C0.236321 12.3465 0.151067 12.4281 0.091648 12.5277C0.0322287 12.6273 0.000857086 12.7411 0.000857086 12.8571C0.000857086 12.9731 0.0322287 13.0869 0.091648 13.1865C0.151067 13.2861 0.236321 13.3677 0.338376 13.4228L8.69557 17.9229C8.885 18.0257 9.11471 18.0257 9.30414 17.9229L17.6613 13.4228C17.7634 13.3677 17.8486 13.2861 17.9081 13.1865C17.9675 13.0869 17.9989 12.9731 17.9989 12.8571C17.9989 12.7411 17.9675 12.6273 17.9081 12.5277C17.8486 12.4281 17.7634 12.3465 17.6613 12.2914L16.487 11.6588L9.91443 15.1988C9.63329 15.3501 9.319 15.4292 8.99975 15.4291C8.6805 15.4289 8.36628 15.3495 8.08528 15.198Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default IntegrationIcon;
