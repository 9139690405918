import { AuthenticationFields, ConvertedField, KMProvider } from './KMProvider';

export const adaptProviderResponse = (data: any): KMProvider[] => {
  return data?.results?.map((provider: any) => ({
    id: provider.id ? provider?.id : '',
    name: provider.name ? provider?.name : '',
    isEnabled: provider.is_connected ? provider?.is_connected : false,
    info: provider.info ? provider?.info : '',
    url: provider.url ? provider?.url : '',
  }));
};

// for getting credential list for authentication
export const adaptCredentialResponse = (data: any): AuthenticationFields[] => {
  return data?.data?.results?.map((field: any) => ({
    name: field.name,
    type: field.type,
    required: field.required,
    readOnly: field.read_only,
    label: field.label,
    maxLength: field.max_length,
    value: '',
  }));
};
