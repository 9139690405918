import Tabs from './components/Tabs.component';
import Content from '../../constants/ContentFile.json';

const pageContent = Content.organization;

const Organization = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold text-neutral-950">
        {pageContent.pageTitle}
      </h2>
      <section className="mt-4">
        <Tabs />
      </section>
    </div>
  );
};

export default Organization;
