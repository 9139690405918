import { ThemeCard, ToggleSwitch } from '@kamet/common';
import withPermissionFlag from '../../hoc/permissionFlag/withPermissionFlag';
import { ADD_PROVIDER } from '../../hoc/permissionFlag/permissionFlag';
interface IIntegrationProps {
  name?: string;
  learnMore: string;
  onChange: (checked: boolean) => void;
  isOn: boolean;
}

const IntegrationCard = (props: IIntegrationProps) => {
  const ToggleSwitchComponent = () => (
    <ToggleSwitch isOn={props.isOn} onChange={props.onChange} />
  );

  const PermissionFlagComponent = withPermissionFlag(ToggleSwitchComponent);
  
  return (
    <ThemeCard>
      <div className="grid gap-3">
        <div className="text-base font-medium text-neutral-950">
          {props.name}
        </div>
        <div className="flex justify-between">
          <a
            href={props.learnMore}
            className="text-primary-700 underline font-medium capitalize"
          >
            Learn more
          </a>
          <PermissionFlagComponent action='hide'permissionName={ADD_PROVIDER}/>

        </div>
      </div>
    </ThemeCard>
  );
};

export default IntegrationCard;
