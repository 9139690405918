// src/LineChart.tsx
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

interface IDoughnutChartProps {
  backgroundColor?: string[];
  chartData?: any;
  variant: string;
  cutout?: number;
  borderRadius?: number;
  borderWidth?: number;
  chartLabels?: number[] | null;
  // modal props
  modalTitle?: string;
  children?: any;
  onClose?: () => void;
  closeButtonText?: string;
  showModal?: boolean;
  onClickSegment?: any;
}

const DoughnutChart = ({
  variant,
  backgroundColor,
  chartData,
  cutout,
  borderRadius,
  borderWidth,
  chartLabels,
  showModal,
  onClickSegment,
}: IDoughnutChartProps) => {
  // Data for the chart - styles
  const defaultChart = {
    datasets: [
      {
        data: [100],
        backgroundColor: ['#E4E4E7'],
        borderWidth: 0,
        hoverBorderColor: ['#ffffff'],
        weight: 10,
      },
    ],
  };

  const primaryChart = {
    datasets: [
      {
        data: chartData,
        backgroundColor: backgroundColor,
        borderColor: ['#ffffff'],
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        hoverBorderColor: ['#ffffff'],
      },
    ],
  };

  // chart onclick function
  const handleChartClick = (event: any, chartElements: any) => {
    if (chartElements.length === 0) return; // If no segment is clicked
    const clickedSegmentIndex = chartElements[0].index;
    const clickedSegmentValue =
      primaryChart.datasets[0].data[clickedSegmentIndex];

    // Pass the clicked segment data to the parent
    if (showModal === true) {
      onClickSegment(clickedSegmentValue);
    }
  };

  // Configuration options for the chart
  const options: ChartOptions<'doughnut'> = {
    onClick: (event, chartElements) => handleChartClick(event, chartElements),
    responsive: true,
    maintainAspectRatio: true,
    cutout: cutout, // inner white space
    plugins: {
      legend: {
        display: false,
      },

      tooltip: {
        // Disable the on-canvas tooltip
        enabled: true,
        backgroundColor: '#ffffff', // Tooltip background color
        titleFont: {
          size: 14,
          weight: 'bold',
          family: 'Arial',
        },
        bodyFont: {
          size: 14,
          family: 'Arial',
        },
        bodyColor: '#000000',
        padding: 10,
        callbacks: {
          label: function (tooltipItem: any) {
            const label = chartLabels ? chartLabels[tooltipItem.dataIndex] : '';
            const value = tooltipItem.raw;
            const eventLabel = Number(label) > 1 ? 'Issues' : 'Issue';
            return `${label} ${eventLabel}`;
          },
        },
      },
    },
  };

  return (
    <>
      {variant === 'default' && (
        <Doughnut data={defaultChart} options={options} />
      )}

      {variant === 'primary' && (
        <>
          <Doughnut data={primaryChart} options={options} />
        </>
      )}
    </>
  );
};

export default DoughnutChart;
