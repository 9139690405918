import { useNavigate } from 'react-router-dom';
import DashboardView from './Dashboard.view';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../redux/store';
import {
  selectDashboardLoading,
  selectDashboardData,
  selectDashboardError,
  fetchDashboradData,
} from './DashboardSlice';
import RouteConstants from '../../constants/routeConstants.json';
import { useEffect } from 'react';
import { Loader } from '@kamet/common';
import ErrorPage from '../../common/component/ErrorPage/Errorpage';

const Dashboard = () => {
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();

  const dashboardData = useSelector((state: RootState) =>
    selectDashboardData(state)
  );
  const loading = useSelector((state: RootState) =>
    selectDashboardLoading(state)
  );
  const error = useSelector((state: RootState) => selectDashboardError(state));

  const onclickEnableButton = () => {
    navigate(RouteConstants.INTEGRATIONS);
  };

  useEffect(() => {
    dispatch(fetchDashboradData());
  }, []);

  // error page action button
  const ErrorActionClick = () => {
    window.location.reload();
  };
  if (loading === 'pending') return <Loader />;
  if (error)
    return (
      <ErrorPage
        statusCode={error.statusCode ? `${error.statusCode}` : ''}
        description={error.message}
        buttonText="Reload"
        isButtonVisible={true}
        action={ErrorActionClick}
      />
    );
  return (
    <>
      <DashboardView
        isEnabled={
          dashboardData && dashboardData.isIntegrated
            ? dashboardData.isIntegrated
            : false
        }
        downTimeDetails={
          dashboardData && dashboardData?.risks && dashboardData?.risks
            ? dashboardData?.risks
            : []
        }
        performaceDetails={
          dashboardData && dashboardData.performanceRisk
            ? dashboardData.performanceRisk
            : null
        }
        operationalDetails={
          dashboardData && dashboardData.operationsRisk
            ? dashboardData.operationsRisk
            : null
        }
        riskDetails={
          dashboardData && dashboardData.analytics
            ? dashboardData.analytics
            : []
        }
        onclikcEnableButton={onclickEnableButton}
      />
    </>
  );
};

export default Dashboard;
