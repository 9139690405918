import { AlertModal, DropDown, Input } from '@kamet/common';
import Content from '../../../constants/ContentFile.json';

interface IAddNewUserProps {
  AddUserModalOpen: boolean;
  onCloseAddUserModal: () => void;
  onClickContinue: () => void;
  // email
  emailValue: string;
  emailErrorMsg: string;
  onChangeEmail: React.ChangeEventHandler<HTMLInputElement>;
  // user role
  roleValue: string;
  SelectErrorMessage: string;
  onChangeSelect: React.ChangeEventHandler<HTMLSelectElement>;
  roleOptions: { value: number | null; label: string }[];
  // discard modal
  showDiscardModal: boolean;
  onCancelDiscardModal: () => void;
  onClickDiscard: () => void;
}

const staticContent = Content.organization.newUserContent;

const AddNewUser = (props: IAddNewUserProps) => {
  return (
    <>
      {/* New user adding modal */}
      <AlertModal
        isOpen={props.AddUserModalOpen}
        title={staticContent.addModalTitle}
        closeButtonText={staticContent.cancelButtonText}
        submitButtonText={staticContent.continueText}
        onClose={props.onCloseAddUserModal}
        onSubmit={props.onClickContinue}
      >
        <p>{staticContent.description}</p>
        <Input
          label={staticContent.emailLabel}
          placeholder={staticContent.emailPlaceholder}
          value={props.emailValue}
          onchange={props.onChangeEmail}
          errorMessage={props.emailErrorMsg}
          errors={props.emailErrorMsg ? true : false}
          variant={props.emailErrorMsg ? 'danger' : 'primary'}
          format={'lowercase'}
        />
        <DropDown
          label={staticContent.userRoleLabel}
          placeholder={staticContent.userRolePlaceholder}
          options={props.roleOptions}
          onChange={props.onChangeSelect}
          value={props.roleValue}
          errorMessage={props.SelectErrorMessage}
          errors={props.SelectErrorMessage ? true : false}
          variant={props.SelectErrorMessage ? 'danger' : 'primary'}
        />
      </AlertModal>

      {/* Discard changes modal */}
      <AlertModal
        title={staticContent.discardModalTitle}
        closeButtonText={staticContent.cancelButtonText}
        submitButtonText={staticContent.discardButtonText}
        isOpen={props.showDiscardModal}
        onClose={props.onCancelDiscardModal}
        onSubmit={props.onClickDiscard}
        submitButtonVariant={true}
      >
        <p>{staticContent.discardModalDescription}</p>
      </AlertModal>
    </>
  );
};

export default AddNewUser;
