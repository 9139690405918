import React, { SVGProps } from 'react';

const HamburgerIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 20"
        fill="CurrentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.333252 1.99996C0.333252 1.26358 0.930206 0.666626 1.66659 0.666626H20.3333C21.0696 0.666626 21.6666 1.26358 21.6666 1.99996C21.6666 2.73634 21.0696 3.33329 20.3333 3.33329H1.66659C0.930206 3.33329 0.333252 2.73634 0.333252 1.99996ZM0.333252 9.99996C0.333252 9.26358 0.930206 8.66663 1.66659 8.66663H20.3333C21.0696 8.66663 21.6666 9.26358 21.6666 9.99996C21.6666 10.7363 21.0696 11.3333 20.3333 11.3333H1.66659C0.930206 11.3333 0.333252 10.7363 0.333252 9.99996ZM0.333252 18C0.333252 17.2636 0.930206 16.6666 1.66659 16.6666H20.3333C21.0696 16.6666 21.6666 17.2636 21.6666 18C21.6666 18.7363 21.0696 19.3333 20.3333 19.3333H1.66659C0.930206 19.3333 0.333252 18.7363 0.333252 18Z"
          fill="CurrentColor"
        />
      </svg>
    </>
  );
};

export default HamburgerIcon;
