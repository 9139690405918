import { VariantProps, cva } from 'class-variance-authority';
import React, { MouseEventHandler } from 'react';
import { cn } from '../../utils';
import { Cancel, ErrorIcon, Search } from '../../assets/icons';
interface InputProps extends VariantProps<typeof inputVariants> {
  name?: string;
  placeholder?: string;
  label?: string;
  errors?: Boolean;
  type?: string;
  value?: string;
  onchange?: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  helpText?: string;
  onCancelSearch?: MouseEventHandler<HTMLDivElement>;
}

const inputVariants = cva(
  'px-10 py-2 w-full h-10 text-base rounded border  outline-none  font-medium font-custom truncate',
  {
    variants: {
      variant: {
        primary:
          'border-neutral-200  focus-visible:border-primary-600 focus:ring focus:ring-primary-200',
        danger:
          ' border-error-700 focus-visible:border-error-700 focus:ring focus:ring-error-200',
        disable: `opacity-50 bg-neutral-200 cursor-not-allowed text-neutral-400 pointer-events-none`,
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

const SearchInput = ({
  variant,
  name,
  placeholder,
  errors,
  onchange,
  type,
  value,
  errorMessage,
  helpText,
  onCancelSearch,
  label,
}: InputProps) => {
  return (
    <div className="flex flex-col">
      <label className="flex float-left text-neutral-950 text-base font-medium">
        {label}
      </label>
      <div className="relative flex items-center">
        <input
          value={value}
          onChange={onchange}
          type={type}
          className={cn(inputVariants({ variant }))}
          placeholder={placeholder}
          name={name}
        />
        <Search className="absolute left-3 top-3 text-base " />
        {value && (
          <div
            onClick={onCancelSearch}
            className="absolute right-3 top-3 text-sm  text-neutral-400 cursor-pointer"
          >
            <Cancel />
          </div>
        )}
      </div>

      {helpText && (
        <div className="flex min-h-5">
          <p className=" text-base font-medium text-neutral-500">{helpText}</p>
        </div>
      )}
      {errors && (
        <div className="flex items-center gap-2">
          <ErrorIcon className="text-xl text-error-700" />
          <p className=" text-base font-medium text-error-700">
            {errorMessage}
          </p>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
