import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  DashboardIcon,
  HamburgerIcon,
  IntegrationIcon,
  OrganizationIcon,
  SettingsIcon,
} from '../../assets/icons';
import Avatar from '../Avatar/Avatar';
import RolesAndPermissionIcon from '../../assets/icons/RolesAndPermissionIcon';
import AdminManagementIcon from '../../assets/icons/AdminManagementIcon';
import Logo from '../../assets/logo.svg';
import clsx from 'clsx';
import routeConstant from '../../../../customer-web/src/constants/routeConstants.json';
// Sidemenu component - for displaying the menu on the left-hand side.
interface ImenuList {
  label?: string;
  path?: string;
  icon?: string;
}
interface ISidemenuProps {
  menuList: ImenuList[];
  userDetails: {
    name: string;
    email: string;
    imageUrl: string;
    businessName: string;
  };
  logoutButton?: React.ReactNode;
}

const menuIcons: any = {
  // Customer web - menu icons
  Dashboard: <DashboardIcon className="text-lg hover:text-primary-700" />,
  Integrations: <IntegrationIcon className="text-lg hover:text-primary-700" />,
  Organization: <OrganizationIcon className="text-lg hover:text-primary-700" />,
  Settings: <SettingsIcon className="text-lg hover:text-primary-700" />,

  // Admin -web - menu icons
  RolesAndPermissions: (
    <RolesAndPermissionIcon className="text-lg hover:text-primary-700" />
  ),
  AdminManagement: (
    <AdminManagementIcon className="text-lg hover:text-primary-700" />
  ),
};

const Sidemenu = (props: ISidemenuProps) => {
  // Function to get firstletter of the user's name
  const firstLetter = props.userDetails?.businessName
    ? props.userDetails?.businessName.charAt(0).toUpperCase()
    : '!';

  const path = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="drawer w-auto lg:drawer-open z-10">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content lg:hidden pt-7 px-4 absolute right-0">
          <label htmlFor="my-drawer" aria-label="open sidebar">
            <HamburgerIcon className="text-neutral-400 hover:text-primary-700 cursor-pointer" />
          </label>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <div className="min-h-full bg-white w-64  border-r border-neutral-200">
            <div
              className="pt-10 px-7 cursor-pointer"
              onClick={() => {
                navigate(routeConstant.DASHBOARD);
              }}
            >
              <img src={Logo} alt="kamet-logo" />
            </div> 
            <ul className="menu text-base-content  w-64 pt-6 px-4">
              {/* Sidebar content here */}
              {props.menuList?.map((item: any, index: number) => {
                return (
                  <li key={index} className="my-1">
                    <Link
                      to={item.path}
                      className={clsx(
                        'rounded font-medium text-base hover:bg-primary-50 hover:text-primary-700 focus:bg-primary-50 focus:text-primary-700',
                        {
                          'bg-primary-50 text-primary-700':
                            path.pathname === item.path,
                        }
                      )}
                    >
                      {menuIcons[item.icon]}
                      <span className="pl-1" data-menu-item={item.label}>
                        {item.label}
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
            <div className="px-4">{props.logoutButton}</div>
            <div className="absolute bottom-0 py-3 px-6 flex ">
              <Avatar>
                {props.userDetails?.imageUrl ? (
                  <img
                    src={props.userDetails?.imageUrl}
                    alt="profile-image"
                    className="object-cover w-full h-full"
                  />
                ) : (
                  firstLetter
                )}
              </Avatar>
              <div className="pl-3  w-40 ">
                <abbr
                  title={
                    props.userDetails?.businessName
                      ? props.userDetails?.businessName
                      : '-'
                  }
                  className="no-underline "
                >
                  <div className="text-neutral-950 text-sm font-semibold truncate">
                    {props.userDetails?.businessName
                      ? props.userDetails?.businessName
                      : '-'}
                  </div>
                </abbr>
                <abbr
                  title={
                    props.userDetails?.email ? props.userDetails?.email : '-'
                  }
                  className="no-underline"
                >
                  <div className="text-neutral-500 text-sm font-medium truncate">
                    {props.userDetails?.email ? props.userDetails?.email : '-'}
                  </div>
                </abbr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidemenu;
