import { useEffect, useState } from 'react';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { Layout } from '@kamet/common';
import RouteConstants from './constants/routeConstants.json';
import ProtectedRoute from './common/securityLayer/ProtectedRoute/ProtectedRouter';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import Permission from './common/securityLayer/Permission/Permission';
import SplashScreen from './features/SplashScreen/SplashScreen.page';
import AuthProtection from './common/securityLayer/AuthProtection/AuthProtection';
import { selectUserInfo } from './features/SplashScreen/SplashSlice';
import { setShowlogoutModal } from './features/Auth/logout/logout.slice';
import LogoutPage from './features/Auth/logout/Logout.page';
import ErrorPage from './common/component/ErrorPage/Errorpage';
import pageConstants from './constants/ContentFile.json'
function App() {
  // To prevent redirection to dashboard while refresh
  //  on window refresh, get current path's hash value and store it in localStorage
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('path', window.location.hash);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const { route, noAccessRoute, pageNotFound } = Permission(); // routes
  const dispatch = useDispatch();
  // sidemenu items from state
  const enabledMenuItems = useSelector((state: RootState) => state.Permissions);
  const userDetails = useSelector((state: RootState) => selectUserInfo(state));
  // logout button click
  const onClicklogoutButton = () => {
    dispatch(setShowlogoutModal(true));
  };
  const { name, role, email, imageUrl,businessName } = userDetails.userDetails;
  const userProfileDetails = { name, role, email, imageUrl,businessName };
  const router = createHashRouter([
    {
      element: <AuthProtection />,
      children: [
        {
          path: RouteConstants.SPLASHSCREEN,
          element: <SplashScreen />,
        },
        {
          element: (
            <Layout
              userDetails={userProfileDetails}
              sidemenuList={enabledMenuItems?.menuList}
              onClicklogoutButton={onClicklogoutButton}
              modalComponent={<LogoutPage />}
            />
          ),
          children: noAccessRoute,
        },
        {
          element: (
            <ProtectedRoute permissionMenuList={enabledMenuItems?.menuList} />
          ),
          children: [
            {
              element: (
                <Layout
                  userDetails={userProfileDetails}
                  sidemenuList={enabledMenuItems?.menuList}
                  onClicklogoutButton={onClicklogoutButton}
                  modalComponent={<LogoutPage />}
                />
              ),
              children: route,
            },
          ],
        },
        {
          element: (
            <Layout
              userDetails={userProfileDetails}
              sidemenuList={enabledMenuItems?.menuList}
              onClicklogoutButton={onClicklogoutButton}
              modalComponent={<LogoutPage />}
            />
          ),
          children: pageNotFound,
        },
      ],
    },
  ]);
  // State to track online/offline status
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  // Effect to listen for online/offline events
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  return (
    <>
      {!isOnline ? (
        <ErrorPage
          statusCode={'503'}
          description={pageConstants.noNetworkError}
          buttonText={'retry'}
          customStyle="px-24"
        />
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
}

export default App;
