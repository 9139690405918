import { VariantProps, cva } from 'class-variance-authority';
import React, { KeyboardEventHandler, useState } from 'react';
import { cn } from '../../utils';
import { ErrorIcon, EyeClose, EyeOpen } from '../../assets/icons';
interface InputProps extends VariantProps<typeof inputVariants> {
  name?: string;
  placeholder?: string;
  label?: String;
  errors?: Boolean;
  type?: string;
  value?: any;
  onchange?: React.ChangeEventHandler<HTMLInputElement>;
  errorMessage?: string;
  helpText?: string;
  required?: boolean;
  minimumValue?: number;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}

const inputVariants = cva(
  'px-4 py-2 w-full h-10 text-base rounded border  outline-none  font-medium font-custom ',
  {
    variants: {
      variant: {
        primary:
          'border-neutral-200  focus-visible:border-primary-600 focus:ring focus:ring-primary-200',
        danger:
          ' border-error-700 focus-visible:border-error-700 focus:ring focus:ring-error-200',
        disable: `border-neutral-200 border opacity-50 bg-neutral-100 cursor-not-allowed text-neutral-700 pointer-events-none`,
      },
      format: {
        normal: 'normal-case',
        capitialized: 'capitalize',
        lowercase: 'lowercase',
      },
    },

    defaultVariants: {
      variant: 'primary',
      format: 'normal',
    },
  }
);

const Input = ({
  variant,
  format,
  name,
  placeholder,
  errors,
  onchange,
  type,
  value,
  errorMessage,
  helpText,
  label,
  required,
  minimumValue,
  onKeyDown,
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputType = type === 'password' && showPassword ? 'text' : type;
  return (
    <div className="flex flex-col gap-2">
      <label className="flex float-left text-neutral-950 text-base font-medium capitalize ">
        {label}
        <span className="text-error-500">{required ? '*' : ''}</span>
      </label>
      <div className="relative">
        <input
          value={value}
          onChange={onchange}
          type={inputType}
          className={cn(inputVariants({ variant, format }))}
          placeholder={placeholder}
          name={name}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          min={minimumValue}
          onKeyDown={onKeyDown}
        />
        {type === 'password' && (
          <div
            onClick={togglePasswordVisibility}
            className="absolute top-3 right-4 cursor-pointer"
          >
            {showPassword ? (
              <EyeClose className="text-xl" />
            ) : (
              <EyeOpen className="text-xl" />
            )}
          </div>
        )}
      </div>
      {helpText && (
        <div className="flex min-h-5">
          <p className=" text-base font-medium text-neutral-500">{helpText}</p>
        </div>
      )}
      <div className="flex items-center gap-2 h-2">
        {errors && (
          <>
            <ErrorIcon className="text-xl text-error-700" />
            <p className=" text-base font-medium text-error-700">
              {errorMessage}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Input;
