import StaticConents from '../../constants/ContentFile.json';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  adaptCredentialResponse,
  adaptProviderResponse,
} from './providerAdapter';
import { KMProvider } from './KMProvider';
import { AppThunk, RootState } from '../../redux/store';
import {
  activateProvider,
  disableProvider,
  getProviderCredentialsDetails,
  getProviders,
  simualteEvent,
} from './provider.service';
import CustomError from '../../common/service/CustomError';

interface ProviderState {
  providers: KMProvider[];
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: CustomError | null;
  inputErrors: Record<string, string>;
  inputs: [];
  showModal: boolean;
}

const initialState: ProviderState = {
  providers: [],
  loading: 'idle',
  error: null,
  inputErrors: {},
  inputs: [],
  showModal: false,
};

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    getProvidersStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getProvidersSuccess(state, action: PayloadAction<KMProvider[]>) {
      state.loading = 'succeeded';
      state.providers = action.payload;
      state.error = null;
    },
    getProvidersFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
    },

    setInputError(state, action: PayloadAction<Record<string, string>>) {
      const { name, error } = action.payload;
      state.inputErrors[name] = error;
    },
    clearInputError(state, action: PayloadAction<string>) {
      const name = action.payload;
      delete state.inputErrors[name];
    },
    setInputErrors(state, action: PayloadAction<Record<string, string>>) {
      state.inputErrors = action.payload;
    },
    reset: (state) => {
      state.inputErrors = {};
      state.inputs = [];
      state.showModal = false;
    },
    setInputs(state, action) {
      state.loading = 'succeeded';
      state.inputs = action.payload;
    },
    setShowModal(state) {
      state.showModal = true;
    },
    disableShowModal(state) {
      state.showModal = false;
    },
  },
});

export const {
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  setInputError,
  clearInputError,
  reset,
  setInputs,
  setInputErrors,
  setShowModal,
  disableShowModal,
} = providersSlice.actions;

// fetch providers
export const fetchProviders = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getProvidersStart());
    const response = await getProviders();
    const providers = adaptProviderResponse(response.data);
    dispatch(getProvidersSuccess(providers));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getProvidersFailure(error));
    } else {
      dispatch(getProvidersFailure(new CustomError('Unknown error')));
    }
  }
};
// options api for getting credentials for provider
export const updateProvider =
  (url: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProvidersStart());
      const response = await getProviderCredentialsDetails(url);
      const credentialList = adaptCredentialResponse(response);
      dispatch(setInputs(credentialList));
      dispatch(setShowModal());
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getProvidersFailure(error));
      } else {
        dispatch(getProvidersFailure(new CustomError('Unknown error')));
      }
    }
  };

// provider integration  using credentials -post api
export const integrateProvider =
  (
    url: string,
    data: {},
    setShowAlertToast: any,
    setAlertToastType: any,
    setToastMessage: any,
    autoSimulateData: any
  ): AppThunk =>
  async (dispatch) => {

    try {
      dispatch(getProvidersStart());
      await activateProvider(url, data);
      await simualteEvent(autoSimulateData);
      // Fetch providers after enabling
      dispatch(fetchProviders());
      setShowAlertToast(true);
      setAlertToastType('success');
      setToastMessage(StaticConents.providerIntegration.integrationEnable);
    } catch (error) {
      if (error instanceof CustomError) {
        setShowAlertToast(true);
        setAlertToastType('error');
        setToastMessage(StaticConents.providerIntegration.enableProviderError);
        dispatch(getProvidersFailure(error));
      } else {
        dispatch(getProvidersFailure(new CustomError('Unknown error')));
      }
    }
  };
// disable providers
export const disableProviders =
  (url: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProvidersStart());
      const response = await disableProvider(`${url}/disable`);
      // Fetch providers after disabling
      dispatch(fetchProviders());
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getProvidersFailure(error));
      } else {
        dispatch(getProvidersFailure(new CustomError('Unknown error')));
      }
    }
  };
export const selectProviders = (state: RootState) => state.providers.providers;
export const selectProvidersLoading = (state: RootState) =>
  state.providers.loading;
export const selectProvidersError = (state: RootState) => state.providers.error;

export default providersSlice.reducer;
