import { VariantProps, cva } from 'class-variance-authority';
import { cn } from '../../utils';
import moment from 'moment';

interface ITileProps extends VariantProps<typeof tileVariants> {
  children?: React.ReactNode;
  date: any;
  onclick?: () => void;
}

const tileVariants = cva('h-6 w-full rounded bg-neutral-200 cursor-pointer', {
  variants: {
    variant: {
      default: 'bg-neutral-200',
      defaultTooltip: 'bg-neutral-200',
      success: 'bg-secondary-500 hover:bg-secondary-600 transition',
      warning: 'bg-warning-500 hover:bg-warning-600 transition',
      danger: ' bg-error-500 hover:bg-error-600 transition',
    },
  },
});

const Tile = ({ variant, children, date, onclick }: ITileProps) => {
  return (
    <div
      className="w-full group relative flex flex-col justify-center items-center title-component "
      onClick={onclick}
    >
      <div className={cn(tileVariants({ variant }))}></div>
      <div className="text-sm text-neutral-500 font-medium mt-2 hidden md:block">
        {date}
      </div>
      <div className="text-sm text-neutral-500 font-medium mt-2 md:hidden">
        {moment(date).format('ddd')}
      </div>
      {variant === 'defaultTooltip' ? (
        ''
      ) : (
        <div className="custom-tooltip absolute min-w-40 w-max top-8 scale-0 rounded bg-neutral-0 p-2 border border-neutral-200 text-sm shadow-lg group-hover:scale-100 z-10">
          {children}
        </div>
      )}
    </div>
  );
};

export default Tile;
