interface IAccordionProps {
  title?: string;
  children?: any;
  details?: React.ReactNode;
}

const Accordion = (props: IAccordionProps) => {
  return (
    <div>
      <div className=" collapse collapse-arrow  bg-neutral-0 rounded-none accrodion">
        <input type="checkbox" />
        <div className="collapse-title text-base font-medium py-2 px-0">
          {props.title}
          <p className="text-base text-neutral-500 font-medium">
            {props.children}
          </p>
        </div>
        <div className="collapse-content text-neutral-500 text-base px-2">
          <p>{props.details}</p>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
