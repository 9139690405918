import React from 'react';
import Button from '../Button/Button';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  title?: string;
  children?: React.ReactNode;
  closeButtonText?: string;
  submitButtonText?: String;
  submitButtonVariant?: boolean;
  cancelButtonStyle?: string;
}

const AlertModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  children,
  closeButtonText,
  submitButtonText,
  submitButtonVariant,
  cancelButtonStyle,
}) => {
  if (!isOpen) return null;

  return (
    <>
      <div
        id="default-modal"
        aria-hidden="true"
        className=" flex items-end md:justify-center md:items-start bg-[#09090B80] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50   md:inset-0 h-[calc(100%-1rem)] md:h-screen max-h-full"
      >
        <div className="relative  w-full md:max-w-lg max-h-full top-20 opacity-1">
          {/* modal content */}
          <div className="relative bg-neutral-0 md:rounded shadow ">
            {/* modal header */}
            <div className="flex items-center justify-between px-5 pt-5">
              <h3 className="text-lg font-semibold text-neutral-950">
                {title}
              </h3>
            </div>
            {/* modal body */}
            <div className="p-4  md:p-4 md:pt-2 space-y-4 text-base text-neutral-950">
              {children}
            </div>
            {/* modal footer */}
            <div className="w-full flex-col-reverse  flex  md:flex md:flex-row md:justify-end items-end p-4 md:p-5 gap-3">
              <Button variant={'secondary'} onClick={onClose}>
                {closeButtonText}
              </Button>
              <div className={cancelButtonStyle}>
                <Button
                  variant={submitButtonVariant ? 'danger' : 'primary'}
                  onClick={onSubmit}
                >
                  {submitButtonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
