import RouteConstants from '../../../constants/routeConstants.json';
import Dashboard from '../../../features/Dashboard/Dashboard.page';
import IntegrationsPage from '../../../features/Integrations/ProviderIntegrations.page';
import Organization from '../../../features/Organization/Organization.page';
import Settings from '../../../features/Settings/CustomerSettings.page';
import ErrorPage from '../../component/ErrorPage/Errorpage';
import Content from '../../../constants/ContentFile.json';
import { setmenuList } from './Permission.slice';
import { useDispatch } from 'react-redux';
import SplashScreen from '../../../features/SplashScreen/SplashScreen.page';

const errorPageContent = Content.errorPageText;

const Permission = () => {
  const dispatch = useDispatch();

  const route = [
    {
      path: RouteConstants.SPLASHSCREEN,
      element: <SplashScreen />,
    },
    {
      path: RouteConstants.DASHBOARD,
      element: <Dashboard />,
    },
    {
      path: RouteConstants.INTEGRATIONS,
      element: <IntegrationsPage />,
    },
    {
      path: RouteConstants.ORGANIZATION,
      element: <Organization />,
    },
    {
      path: RouteConstants.SETTINGS,
      element: <Settings />,
    },
  ];

  const noAccessRoute = [
    {
      path: RouteConstants.NO_ACCESS,
      element: (
        <ErrorPage
          statusCode={errorPageContent.statusCode}
          description={errorPageContent.description}
          buttonText={errorPageContent.buttonText}
          //routeName={RouteConstants.DASHBOARD}
        />
      ),
    },
  ];

  const pageNotFound = [
    {
      path: '*',
      element: (
        <ErrorPage
          statusCode={errorPageContent.statusCode}
          description={errorPageContent.description}
          buttonText={errorPageContent.buttonText}
          //routeName={RouteConstants.DASHBOARD}
        />
      ),
    },
  ];

  // sorting route with permission from all routes
  const createPermissionRoutes = (menulist: any, permissionMenuList: any) => {
    const routeWithPermission = menulist.filter((menuItem: any) => {
      return permissionMenuList.some(
        (role: any) => role.title === menuItem.label && role.enable
      );
    });
    dispatch(setmenuList(routeWithPermission));
  };

  return { route, noAccessRoute, pageNotFound, createPermissionRoutes };
};

export default Permission;
