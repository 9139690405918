import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { adaptDashboardResponse } from './adaptDashboardResponse';
import { KAMDashboardModel } from './KAMDashboardModel';
import { AppThunk, RootState } from '../../redux/store';
import {
  getDashboardData,
  WeeklyOperationDetails,
  WeeklyPerformanceDetails,
} from './dashboard.service';
import CustomError from '../../common/service/CustomError';

interface DashboardState {
  dashboardData: KAMDashboardModel | null;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: CustomError | null;
  performaceDetails: any;
  operationDetails: any;
}

const initialState: DashboardState = {
  dashboardData: null,
  loading: 'idle',
  error: null,
  performaceDetails: null,
  operationDetails: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getDashboardStart(state) {
      state.loading = 'pending';
      state.error = null;
    },
    getDashboardSuccess(state, action: PayloadAction<KAMDashboardModel>) {
      state.loading = 'succeeded';
      state.dashboardData = action.payload;
      state.error = null;
    },
    getDashboardFailure(state, action: PayloadAction<CustomError>) {
      state.loading = 'failed';
      state.error = action.payload;
    },
    getPerformanceDetails(state, action) {
      state.performaceDetails = action.payload;
    },
    getOperationDetails(state, action) {
      state.operationDetails = action.payload;
    },
  },
});

export const {
  getDashboardStart,
  getDashboardSuccess,
  getDashboardFailure,
  getPerformanceDetails,
  getOperationDetails,
} = dashboardSlice.actions;

// fetch DashboardData
export const fetchDashboradData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getDashboardStart());
    const response = await getDashboardData();
    const dashboardData = adaptDashboardResponse(response);
    dispatch(getDashboardSuccess(dashboardData));
  } catch (error) {
    if (error instanceof CustomError) {
      dispatch(getDashboardFailure(error));
    } else {
      dispatch(getDashboardFailure(new CustomError('Unknown error')));
    }
  }
};

// peformance chart modal details
export const fetchPerformaceDetails =
  (value: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await WeeklyPerformanceDetails(value);
      dispatch(getPerformanceDetails(response));
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getDashboardFailure(error));
      } else {
        dispatch(getDashboardFailure(new CustomError('Unknown error')));
      }
    }
  };

// operation chart modal details
export const fetchWeeklyOperationDetails =
  (value: number): AppThunk =>
  async (dispatch) => {
    try {
      const response = await WeeklyOperationDetails(value);
      dispatch(getOperationDetails(response));
    } catch (error) {
      if (error instanceof CustomError) {
        dispatch(getDashboardFailure(error));
      } else {
        dispatch(getDashboardFailure(new CustomError('Unknown error')));
      }
    }
  };

export const selectDashboardData = (state: RootState) =>
  state.dashboard.dashboardData;
export const selectDashboardLoading = (state: RootState) =>
  state.dashboard.loading;
export const performaceDetailsData = (state: RootState) =>
  state.dashboard.performaceDetails;

export const operationDetailsData = (state: RootState) =>
  state.dashboard.operationDetails;

export const selectDashboardError = (state: RootState) => state.dashboard.error;

export default dashboardSlice.reducer;
