
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { store, RootState } from '../../../redux/store';

interface WithPermissionFlagProps {
  permissionName: string;
  action: 'show' | 'hide' | 'greyout';

}

const withPermissionFlag = <P extends object>(Component: FC<P>) => {
  const WrappedComponent: FC<P & WithPermissionFlagProps> = ({ permissionName, action, ...props }) => {
  const permissions = useSelector((store: RootState) => store.userDetails.rolesNPermissions);
  const permission = permissions.find(f => f.codeName === permissionName);
  const isSuperUser = useSelector((store: RootState) => store.userDetails.userDetails.isSuperUser);
    if(isSuperUser) {
       return <Component {...props as P} />;
    } else if(permission){
      return <Component {...props as P} />;
    } else {
        if(action === 'hide') {
          return null
        } else {
          return <div style={{ opacity: 0.5, pointerEvents: 'none' }}><Component {...props as P} /></div>;
        }
     
    }
  };

  return WrappedComponent;
};
 export default withPermissionFlag;


// How to implemement

/// import withPermissionFlag from './hoc/permissionFlag/withPermissionFlag';

// const PermissionFlaggedComponent = withPermissionFlag(SomeComponent);

{/* <PermissionFlaggedComponent permissionName="somePermission" action="greyout"// /> */}