import React, { SVGProps } from 'react';

// organization icon for sidemenu

const OrganizationIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 22 18"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.39999 3.6C7.39999 2.64522 7.77928 1.72955 8.45441 1.05441C9.12954 0.379284 10.0452 0 11 0C11.9548 0 12.8704 0.379284 13.5456 1.05441C14.2207 1.72955 14.6 2.64522 14.6 3.6C14.6 4.55478 14.2207 5.47045 13.5456 6.14558C12.8704 6.82071 11.9548 7.2 11 7.2C10.0452 7.2 9.12954 6.82071 8.45441 6.14558C7.77928 5.47045 7.39999 4.55478 7.39999 3.6ZM14.6 6.48C14.6 5.71617 14.9034 4.98363 15.4435 4.44353C15.9836 3.90342 16.7162 3.6 17.48 3.6C18.2438 3.6 18.9763 3.90342 19.5165 4.44353C20.0566 4.98363 20.36 5.71617 20.36 6.48C20.36 7.24382 20.0566 7.97636 19.5165 8.51646C18.9763 9.05657 18.2438 9.35999 17.48 9.35999C16.7162 9.35999 15.9836 9.05657 15.4435 8.51646C14.9034 7.97636 14.6 7.24382 14.6 6.48ZM1.64 6.48C1.64 5.71617 1.94343 4.98363 2.48353 4.44353C3.02364 3.90342 3.75617 3.6 4.52 3.6C5.28382 3.6 6.01636 3.90342 6.55646 4.44353C7.09657 4.98363 7.39999 5.71617 7.39999 6.48C7.39999 7.24382 7.09657 7.97636 6.55646 8.51646C6.01636 9.05657 5.28382 9.35999 4.52 9.35999C3.75617 9.35999 3.02364 9.05657 2.48353 8.51646C1.94343 7.97636 1.64 7.24382 1.64 6.48ZM5.5376 11.6323C6.12315 10.7146 6.93063 9.95928 7.88539 9.43626C8.84015 8.91324 9.91136 8.63938 11 8.63999C11.9118 8.63916 12.8135 8.83092 13.6461 9.20271C14.4787 9.5745 15.2234 10.1179 15.8314 10.7975C16.4394 11.477 16.8971 12.2773 17.1744 13.1459C17.4517 14.0145 17.5424 14.9319 17.4406 15.8381C17.4282 15.9506 17.3894 16.0587 17.3273 16.1534C17.2653 16.2482 17.1817 16.327 17.0835 16.3833C15.2322 17.4456 13.1344 18.0031 11 18C8.78719 18 6.7088 17.4125 4.91648 16.3833C4.81826 16.327 4.73473 16.2482 4.67268 16.1534C4.61063 16.0587 4.5718 15.9506 4.55936 15.8381C4.39813 14.366 4.74316 12.883 5.5376 11.6333V11.6323Z"
          fill="currentColor"
        />
        <path
          d="M4.35872 10.8038C3.41192 12.2653 2.9708 13.9971 3.10304 15.7334C2.52656 15.646 1.95959 15.5046 1.4096 15.311L1.2992 15.2726C1.2007 15.2377 1.11445 15.1749 1.05089 15.0919C0.98734 15.0089 0.949209 14.9093 0.941119 14.8051L0.931519 14.689C0.89274 14.207 0.951445 13.7222 1.10413 13.2634C1.25682 12.8046 1.50036 12.3813 1.82021 12.0187C2.14007 11.6561 2.52969 11.3617 2.96583 11.1529C3.40197 10.9442 3.87569 10.8255 4.35872 10.8038ZM18.8969 15.7334C19.0292 13.9971 18.5881 12.2653 17.6413 10.8038C18.1243 10.8255 18.598 10.9442 19.0342 11.1529C19.4703 11.3617 19.8599 11.6561 20.1798 12.0187C20.4996 12.3813 20.7432 12.8046 20.8959 13.2634C21.0485 13.7222 21.1072 14.207 21.0685 14.689L21.0589 14.8051C21.0506 14.9091 21.0124 15.0086 20.9488 15.0914C20.8853 15.1742 20.7991 15.2368 20.7008 15.2717L20.5904 15.3101C20.0461 15.5021 19.4806 15.6451 18.8969 15.7334Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default OrganizationIcon;
