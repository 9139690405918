import { isValidValue } from '@kamet/common';
import { setEmailErrorMsg, setRoleErrorMsg } from './newUser.slice';
import Content from '../../../constants/ContentFile.json';

const staticContent = Content.organization.newUserContent;

// Validate empty value
const isEmpty = (value: any) => {
  const error = value?.trim() === '' ? ' required' : '';
  return error === ''; // Return true if no error
};

// Email Validation
const isValidEmail = (email: string, dispatch: any) => {
  if (email === '') {
    dispatch(setEmailErrorMsg(staticContent.emailRequired));
    return false;
  } else {
    const isValid = isValidValue(email, 'email');
    if (isValid) {
      dispatch(setEmailErrorMsg(''));
    } else {
      dispatch(setEmailErrorMsg(staticContent.invaidEmail));
    }
    return isValid;
  }
};

// user role validation
const isValidUserRole = (role: any, dispatch: any) => {
  if (role !== '') {
    dispatch(setRoleErrorMsg(''));
    return true;
  } else {
    dispatch(setRoleErrorMsg(staticContent.roleRequired));
    return false;
  }
};

export { isValidEmail, isValidUserRole };
