import { MouseEventHandler } from 'react';
import LogoutButton from '../LogoutButton/LogoutButton';
import Sidemenu from '../Sidemenu/Sidemenu';
import { Outlet } from 'react-router-dom';

// Layout component - which integrates the Navbar, sidemenu, and page components according to the UI design.

// user  details interface for both sidemenu and navbar
interface IUserDetails {
  name: string;
  role: string | number;
  imageUrl: string;
  email: string;
  businessName:string
}

// Sidemenu details interface
interface ISidemenuList {
  label?: string;
  path?: string;
  icon?: string;
}

// Layout interface
interface ILayoutProps {
  userDetails: IUserDetails;
  sidemenuList: ISidemenuList[];
  onClicklogoutButton?: MouseEventHandler<HTMLButtonElement>;
  modalComponent?: React.ReactNode;
}

const Layout = (props: ILayoutProps) => {
  return (
    <>
      <div className="lg:flex">
        <Sidemenu
          menuList={props.sidemenuList}
          userDetails={props.userDetails}
          logoutButton={<LogoutButton onClick={props.onClicklogoutButton} />}
        ></Sidemenu>
        {props.modalComponent}
        <div className="w-full bg-neutral-50 py-4 px-4 lg:px-8 min-h-screen ">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
