import { cva, type VariantProps } from 'class-variance-authority';
import React, { MouseEventHandler } from 'react';
import { cn } from '../../utils';

interface IButtonProps extends VariantProps<typeof buttonVariants> {
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  customWidth?: string;
}

const buttonVariants = cva(
  'justify-center flex  items-center gap-3 px-4 py-2 rounded text-base font-medium tracking-tight font-custom',
  {
    variants: {
      variant: {
        primary:
          'bg-primary-600 text-neutral-100 hover:bg-primary-700 focus:ring focus:ring-primary-200 ',
        secondary:
          ' border border-neutral-200 text-neutral-950  hover:bg-neutral-50 focus:border-primary-600 focus:ring focus:ring-primary-200',
        danger:
          ' bg-error-600  hover:bg-error-700 text-neutral-100 focus:ring focus:ring-error-200 ',
        dark: 'bg-neutral-800 text-neutral-100 hover:bg-neutral-950 focus:ring focus:ring-neutral-200',
        disable: `opacity-50 bg-transparent cursor-not-allowed text-neutral-400 pointer-events-none p-0`,
        default: 'bg-transparent p-0',
      },
      size: {
        default: 'h-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
);
const Button = ({
  variant,
  size,
  children,
  onClick,
  customWidth,
}: IButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        buttonVariants({ variant, size }),
        customWidth ? customWidth : 'w-full md:w-auto'
      )}
    >
      {children}
    </button>
  );
};

export default Button;
