import { IPermission, IUserProfileDetails } from './SplashscreenSettings';

export const adaptUserDetailsResponse = (data: any): IUserProfileDetails => {

  return {
    name: data?.name ?? '',
    role: data?.role ?? '',
    email: data?.email ?? '',
    phoneNumber: data?.phone_number ?? '',
    timezone: data?.time_zone ?? '',
    isEmailNotification: data?.preference.email_notification ?? false,
    isSmsNotification: data?.preference.sms_nofification ?? false,
    imageUrl: data?.logo ?? '',
    isSuperUser: data?.is_superuser ?? false,
    businessName:data?.business_name??''
  };
};

export const adaptRolesNPermissionsResponse = (data:any): IPermission[] => {
  return data.map((permission: any) => ({
    name: permission.name??'',
    codeName: permission.codename??''
  }));
}
