import type { SVGProps } from 'react';
const SvgEyeOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}  >
    <path
      d="M10.8505 8.92835C12.3681 8.92835 13.5993 7.72902 13.5993 6.24994C13.5993 4.77087 12.3681 3.57153 10.8505 3.57153C9.33174 3.57153 8.10156 4.77087 8.10156 6.24994C8.10156 7.72902 9.33174 8.92835 10.8505 8.92835Z"
      fill="#A1A1AA"
    />
    <path
      d="M20.9991 6.03851C20.9991 5.96803 17.5453 0.258789 10.8493 0.258789C4.1533 0.258789 0.699563 6.03851 0.699563 6.03851L0.558594 6.24997L0.629078 6.46142C0.629078 6.5319 4.08282 12.2411 10.7788 12.2411C17.4749 12.2411 20.9286 6.46142 20.9286 6.46142L20.9991 6.24997V6.03851ZM10.8493 9.98564C8.73479 9.98564 7.04316 8.29401 7.04316 6.24997C7.04316 4.20592 8.73479 2.51429 10.8493 2.51429C12.9639 2.51429 14.6555 4.20592 14.6555 6.24997C14.6555 8.29401 12.9639 9.98564 10.8493 9.98564Z"
      fill="#A1A1AA"
    />
  </svg>
);
export default SvgEyeOpen;
