import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../KeyCloakAuthentication/useAuth';
import { Loader } from '@kamet/common';
import RouteConstants from '../../../constants/routeConstants.json';

const AuthProtection = () => {
  const navigate = useNavigate();
  const onCompletion = () => {
    navigate(RouteConstants.SPLASHSCREEN);
  };
  const isLogin = useAuth(onCompletion);

  return isLogin ? (
    <Outlet />
  ) : (
    <div>
      <Loader />
    </div>
  );
};

export default AuthProtection;
