import { Accordion, AlertModal, DoughnutChart, ThemeCard } from '@kamet/common';
import clsx from 'clsx';
import { OverallRisk } from '../KAMDashboardModel';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPerformaceDetails,
  performaceDetailsData,
} from '../DashboardSlice';
import { AppDispatch, RootState } from '../../../redux/store';
import { riskDescriptions } from '../../../constants/riskDescriptions';
import {
  convertDate,
  convertDateTime,
  convertTime,
  convertUtcToSelectedTimezone,
} from '../../../common/utils/imageUpload.utils';

interface IRiskChartProps {
  performaceRiskDetails: any;
}
interface Machine {
  name: string;
}
interface Data {
  total_machines: number;
  machines: Machine[];
  start_time: string;
  start_date: string;
  end_time: string;
  end_date: string;
  status: boolean;
}
interface ModalDataType {
  [riskType: string]: {
    [category: string]: Data[];
  };
}
type RiskLevel = 'high_risk' | 'medium_risk' | 'low_risk';

interface RiskDescriptions {
  [key: string]: {
    [key in RiskLevel]: string;
  };
}

const RiskChartCard = (props: IRiskChartProps) => {
  const [modal, setModal] = useState(false);
  const [riskType, setRiskType] = useState<any>('');
  const dispatch = useDispatch() as AppDispatch;
  const performaceData = useSelector((state: RootState) =>
    performaceDetailsData(state)
  );
  const customerSettingslInfo = useSelector(
    (state: RootState) => state.customerInfo
  );

  const modalData = performaceData?.results;

  const performaceDetails = props.performaceRiskDetails;
  const chartData = [
    // {
    //   id: 1,
    //   value: performaceDetails?.lowRiskPercentage
    //     ? performaceDetails?.lowRiskPercentage
    //     : 0,
    // },
    {
      id: 2,
      value: performaceDetails?.totalMediumRisk
        ? performaceDetails?.totalMediumRisk
        : 0,
    },
    {
      id: 3,
      value: performaceDetails?.totalHighRisk
        ? performaceDetails?.totalHighRisk
        : 0,
    },
  ];

  const percentageArray = [
    // performaceDetails?.lowRiskPercentage
    //   ? performaceDetails?.lowRiskPercentage
    //   : 0,
    performaceDetails?.totalMediumRisk ? performaceDetails?.totalMediumRisk : 0,
    performaceDetails?.totalHighRisk ? performaceDetails?.totalHighRisk : 0,
  ];

  const riskCount = [
    // performaceDetails?.totalLowRisk ? performaceDetails?.totalLowRisk : 0,
    performaceDetails?.totalMediumRisk ? performaceDetails?.totalMediumRisk : 0,
    performaceDetails?.totalHighRisk ? performaceDetails?.totalHighRisk : 0,
  ];

  const sumValue =
    percentageArray &&
    percentageArray.reduce(
      (accumulator: any, currentValue: any) => accumulator + currentValue,
      0
    );

  const coloredPercentageArray = [
    // {
    //   percentage: performaceDetails?.lowRiskPercentage
    //     ? performaceDetails?.lowRiskPercentage
    //     : 0,
    //   color: '#10B981', // Low risk is green
    // },
    {
      percentage: performaceDetails?.totalMediumRisk
        ? performaceDetails?.totalMediumRisk
        : 0,
      color: '#F59E0B', // Medium risk is orange
    },
    {
      percentage: performaceDetails?.totalHighRisk
        ? performaceDetails?.totalHighRisk
        : 0,
      color: '#EF4444', // High risk is red
    },
  ];
  // Creating an array of risk objects
  const risks = [
    {
      title: 'No Risk',
      percentage: performaceDetails?.lowRiskPercentage
        ? performaceDetails?.lowRiskPercentage
        : 0,
      status: 'Low',
    },
    {
      title: 'Medium Risk',
      percentage: performaceDetails?.mediumRiskPercentage
        ? performaceDetails?.mediumRiskPercentage
        : 0,
      status: 'Medium',
    },
    {
      title: 'High Risk',
      percentage: performaceDetails?.highRiskPercentage
        ? performaceDetails?.highRiskPercentage
        : 0,
      status: 'High',
    },
  ];
  // Determine the background colors based on the percentages
  const bgColors = coloredPercentageArray.map((item) =>
    item.percentage > 0 ? item.color : ''
  );

  const hundredPercentage = percentageArray?.includes(100);

  // Handle the segment click and update state
  const handleSegmentClick = (value: any) => {
    if (value) {
      setRiskType(
        value.id === 3
          ? 'high_risk'
          : value.id === 2
            ? 'medium_risk'
            : 'low_risk'
      );
      dispatch(fetchPerformaceDetails(value.id));
      riskCount.map((item: any) => {
        if (item > 0) {
          setModal(true);
        }
      });
    } else {
    }
  };

  const closeModal = () => {
    setModal(false);
  };
  const getRiskDescription = (
    category: string,
    riskType: RiskLevel
  ): string | undefined => {
    const firstWord = category.split(' ')[0];
    const descriptions = riskDescriptions[firstWord];
    if (!descriptions) {
      return;
    }
    const description = descriptions[riskType];
    if (!description) {
      return;
    }
    return description;
  };
  return (
    <>
      <ThemeCard>
        <h3 className="font-medium text-base text-neutral-950 text-center">
          {'Weekly Performance Risk'}
        </h3>
        <div className="flex justify-center">
          <div className="mt-4 w-36">
            <DoughnutChart
              variant={
                percentageArray &&
                percentageArray.length > 0 &&
                sumValue &&
                sumValue > 0
                  ? 'primary'
                  : 'default'
              }
              backgroundColor={bgColors ? bgColors : ['#777']}
              chartData={chartData}
              cutout={35}
              borderWidth={hundredPercentage ? 0 : 4}
              borderRadius={hundredPercentage ? 0 : 5}
              chartLabels={riskCount} // Pass titles to the chart
              showModal={true}
              onClickSegment={handleSegmentClick}
            />
          </div>
        </div>

        <section>
          {risks.map((data) => {
            return (
              <div
                key={data.title}
                className="border-b border-neutral-200 flex justify-between pb-1 mb-2 last:border-0"
              >
                <div className="flex">
                  <div
                    className={clsx('w-1 h-6 rounded-md', {
                      'bg-neutral-200': data.status === '', // Default case if no status is set
                      'bg-secondary-500': data.title === 'No Risk', // Green for Low Risk
                      'bg-warning-500': data.title === 'Medium Risk', // Orange for Medium Risk
                      'bg-error-500': data.title === 'High Risk', // Red for High Risk
                    })}
                  ></div>
                  <span className="ml-2 text-base text-neutral-500 font-medium">
                    {data.title}
                  </span>
                </div>
                <span className="text-base text-neutral-950 font-medium">
                  {data.percentage}% &nbsp;of fleet
                </span>
              </div>
            );
          })}
        </section>
      </ThemeCard>
      <AlertModal
        isOpen={modal}
        onClose={closeModal}
        title={`Weekly ${
          riskType === 'high_risk'
            ? 'High'
            : riskType === 'medium_risk'
              ? 'Medium'
              : 'Low'
        } Performance Risk`}
        closeButtonText="Close"
        cancelButtonStyle="hidden"
      >
        {modalData && modalData[riskType] && (
          <>
            {/* {/ Render ongoing items first /} */}
            {modalData[riskType]['ongoing'] &&
              Object.keys(modalData[riskType]['ongoing'] || {}).map(
                (category) => (
                  <div
                    key={category}
                    className={`${
                      modalData[riskType]['ongoing']?.[category].length > 2
                        ? 'overflow-y-scroll  scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-50 '
                        : ''
                    } max-h-[200px] border-t last:border-b `}
                  >
                    {modalData[riskType]['ongoing']?.[category].map(
                      (data: Data, index: number) => (
                        <Accordion
                          key={index}
                          title={`${data.total_machines} ${
                            data.total_machines > 1
                              ? `machines are`
                              : `machine is`
                          } ${getRiskDescription(category, riskType)}`}
                          details={data.machines
                            .map((machine: any) => machine.name)
                            .filter(
                              (name: string, index: number, self: string[]) =>
                                self.indexOf(name) === index
                            ) // Filter to get unique names
                            .join(', ')}
                        >
                          <div className="flex gap-2 mt-1">
                            <div className="text-neutral-700 text-sm bg-neutral-100 px-3 py-0.5 rounded">
                              {`Started ${convertUtcToSelectedTimezone(data.start_date, data.start_time, customerSettingslInfo?.timezone ? customerSettingslInfo?.timezone : '')} `}
                            </div>
                            <div
                              className={clsx('rounded px-2 py-0.5 text-sm', {
                                'bg-error-100 text-error-500':
                                  data?.status === false,
                                'bg-secondary-100 text-secondary-700':
                                  data?.status === true,
                              })}
                            >
                              Ongoing
                            </div>
                          </div>
                        </Accordion>
                      )
                    )}
                  </div>
                )
              )}

            {/* {/ Render fixed items after ongoing /} */}
            {modalData[riskType]['fixed'] &&
              Object.keys(modalData[riskType]['fixed'] || {}).map(
                (category) => (
                  <div
                    key={category}
                    className={`${
                      modalData[riskType]['fixed']?.[category].length > 2
                        ? 'overflow-y-scroll  scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-50 '
                        : ''
                    } max-h-[200px] border-t last:border-b `}
                  >
                    {modalData[riskType]['fixed']?.[category].map(
                      (data: Data, index: number) => (
                        <Accordion
                          key={index}
                          title={`${data.total_machines} ${
                            data.total_machines > 1
                              ? `machines are`
                              : `machine is`
                          } ${getRiskDescription(category, riskType)}`}
                          details={data.machines
                            .map((machine: any) => machine.name)
                            .join(', ')}
                        >
                          <div className="flex gap-2 mt-1">
                            <div className="text-neutral-700 text-sm bg-neutral-100 px-3 py-0.5 rounded">
                              {`Started ${convertUtcToSelectedTimezone(data.start_date, data.start_time, customerSettingslInfo?.timezone ? customerSettingslInfo?.timezone : '')} `}
                            </div>
                            <div
                              className={clsx('rounded px-2 py-0.5 text-sm', {
                                'bg-error-100 text-error-500':
                                  data?.status === false,
                                'bg-secondary-100 text-secondary-700':
                                  data?.status === true,
                              })}
                            >
                              {`Fixed at ${convertUtcToSelectedTimezone(data.end_date, data.end_time, customerSettingslInfo?.timezone ? customerSettingslInfo?.timezone : '')} `}
                            </div>
                          </div>
                        </Accordion>
                      )
                    )}
                  </div>
                )
              )}
          </>
        )}
      </AlertModal>
    </>
  );
};

export default RiskChartCard;
