import { ThemeCard, Tile } from '@kamet/common';
import moment from 'moment';
import clsx from 'clsx';
import { ErrorIcon, Success, Warning } from '@kamet/common/src/assets/icons';
import { DailyRisks } from '../KAMDashboardModel';
import RiskDetailsModal from './RiskDetailsModal';
import { useState } from 'react';
interface IDownTimeCardProps {
  pageContent: any;
  downTimeDetails: any;
  isProviderIntegrated: Boolean;
}
const DowntimeCard = (props: IDownTimeCardProps) => {
  const cardStaticData = props.pageContent;
  const downTimeDetails = props.downTimeDetails;
  const [isRiskModalopen, setIsRiskModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const getVariant: any = (details: any) => {
    if (props.isProviderIntegrated === true) {
      if (details.highRisk > 0) {
        return 'danger'; // High risk
      } else if (details.mediumRisk > 0) {
        return 'warning'; // Medium risk
      } else if (details.lowRisk > 0) {
        return 'success'; // Low risk
      } else {
        return 'success'; // for no risk
      }
    }
    return 'defaultTooltip'; // Default
  };

  const showRiskDetailsModal = (details: any, events: any, date: string) => {
    if (details?.highRisk != 0 || details?.mediumRisk != 0) {
      if (events > 0) {
        setSelectedEvent(date);
        setIsRiskModalOpen(true);
      } else {
      }
    }
  };

  const onClickModalClose = () => {
    setIsRiskModalOpen(false);
  };
  const formatTime = (time: any) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);
    return `${hours}hr ${minutes}min`;
  };

  return (
    <>
      <ThemeCard>
        <div className="flex justify-between font-medium text-neutral-950 text-base">
          <span>{cardStaticData.downTimeTitle}</span>
          <span>{`Last ${downTimeDetails && downTimeDetails?.length ? downTimeDetails?.length : 0} days`}</span>{' '}
        </div>
        <section className="mt-2 ">
          <div className="flex gap-1">
            {downTimeDetails &&
              downTimeDetails?.map((details: any) => {
                const cost = Number(details.cost?.toFixed(2));
                const breakDownEvents = [
                  { event: 'Time', value: `${formatTime(details.time)}` },
                  { event: 'Cost', value: `$${cost}` },
                ];
                const formattedDate = moment(details.date).format(
                  'ddd, MMM DD'
                );
                const length = details?.count;

                return (
                  <Tile
                    variant={getVariant(details)}
                    date={formattedDate}
                    // onclick={() =>
                    //   showRiskDetailsModal(details, details.count, details.date)
                    // }
                  >
                    <span className="flex justify-centertext-sm font-medium text-neutral-950 ">
                      {moment(details.date).format('dddd, MMM DD')}
                    </span>
                    <div
                      // className={clsx('py-1 flex items-center gap-1', {
                      //   'text-secondary-500':
                      //     details.lowRisk >= 0 &&
                      //     details.highRisk === 0 &&
                      //     details.mediumRisk === 0, // No high or medium risk, only low risk
                      //   'text-warning-500 border-b border-neutral-200':
                      //     details.mediumRisk > 0 && details.highRisk === 0, // No high risk, but medium risk exists
                      //   'text-error-500 border-b border-neutral-200':
                      //     details.highRisk > 0, // High risk exists
                      // })}
                      className="py-1 flex items-center gap-1 text-neutral-700"
                    >
                      {details.highRisk > 0 ? (
                        <>
                          {/* <ErrorIcon /> */}
                          <Warning />
                          <span>
                            {length}{' '}
                            {length > 1
                              ? cardStaticData.eventsDetected
                              : cardStaticData.eventDetected}
                          </span>
                        </>
                      ) : details.mediumRisk > 0 ? (
                        <>
                          <Warning />
                          <span>
                            {length}{' '}
                            {length > 1
                              ? cardStaticData.eventsDetected
                              : cardStaticData.eventDetected}
                          </span>
                        </>
                      ) : details.lowRisk > 0 ? (
                        <span>{cardStaticData.noMajorEvent}</span>
                      ) : (
                        <span>{cardStaticData.noMajorEvent}</span>
                      )}
                    </div>
                    {details.highRisk > 0 || details.mediumRisk > 0 ? (
                      <>
                        {breakDownEvents?.map((data) => {
                          return (
                            <div className="flex justify-between mt-1 gap-2">
                              <span className="text-sm text-neutral-500 font-medium">
                                {data.event}
                              </span>
                              <div className="text-sm font-medium text-neutral-950">
                                {data.value}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </Tile>
                );
              })}
          </div>
        </section>
      </ThemeCard>

      <RiskDetailsModal
        isOpen={isRiskModalopen}
        onClose={onClickModalClose}
        data={selectedEvent}
      />
    </>
  );
};

export default DowntimeCard;
